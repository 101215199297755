import React from 'react';
import PropTypes from 'prop-types';

import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';

export const Nav = ({ user, toggleModal }) => {
  return (
    <>
      <MobileNav user={user} toggleModal={toggleModal} />
      <DesktopNav user={user} toggleModal={toggleModal} />
    </>
  );
};

Nav.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
