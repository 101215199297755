import styled, { css } from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import { Typography } from 'components/atoms';

export const Section = styled.div`
  padding: 2rem 0;
  &:not(:last-child) {
    border-bottom: 5px solid ${props => props.theme.colors.primary}14;
  }
`;

export const SubSection = styled.div`
  margin-bottom: 2rem;
`;

export const SectionTitle = styled(Typography.Heading)`
  margin-bottom: 1.5rem;
  padding-left: 1rem;
`;

export const RoomWrapper = styled(Flex)`
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const List = styled.ol`
  padding-left: 1.5rem;

  ${({ type }) =>
    type &&
    css`
      > li {
        list-style-type: ${type};
      }
    `}
`;

export const ListItem = styled(Typography.Paragraph)`
  display: list-item;
  margin-bottom: 1rem;

  ol {
    margin-top: 1rem;
  }
`;

export const CheckoutSubItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const CheckoutItem = styled(Box)`
  padding: 0 1rem;
`;

export const CheckoutBox = styled(Flex)`
  margin-bottom: 3rem;
  flex-direction: column;

  ${CheckoutItem} {
    &:first-child {
      width: 100%;
    }
    &:last-child {
      width: 100%;
    }
  }
  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: 2rem;
    flex-direction: row;

    ${CheckoutItem} {
      &:first-child {
        width: 13rem;
      }
      &:last-child {
        width: calc(100% - 13rem);
      }
    }
  }
`;

export const DiscountBox = styled(Box)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem 0;
  position: relative;

  &:before,
  &:after {
    width: 4rem;
    height: 1px;
    background-color: #000;
    position: absolute;
    content: '';
    right: 0;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
`;

export const TooltipWrapper = styled(Box)`
  display: flex;
  align-items: center;
  .__react_component_tooltip.show {
    box-shadow: 0px 4px 84px rgba(0, 0, 0, 0.1);
  }
`;

export const TooltipBody = styled.div`
  // box-shadow: 0px 4px 84px rgba(0, 0, 0, 0.1);
  max-width: 20rem;
`;

SectionTitle.defaultProps = {
  type: 'h4',
};

ListItem.defaultProps = {
  as: 'li',
};

List.defaultProps = {
  type: 'initial',
};
