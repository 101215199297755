import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { ReactComponent as Calendar } from 'assets/svg/calendar.svg';
import 'react-day-picker/lib/style.css';
import './DayPicker.css';
import { InputGroup } from './Input';

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

export const DayPicker = props => {
  const FORMAT = 'dd/MM/yyyy';
  return (
    <DayPickerInput
      keepFocus={false}
      formatDate={formatDate}
      format={FORMAT}
      parseDate={parseDate}
      style={{ display: 'block' }}
      dayPickerProps={{ ...props }}
      {...props}
      component={inputProps => (
        <InputGroup
          addon={<Calendar height={16} />}
          addonPosition="right"
          inputProps={{ ...inputProps, readOnly: true }}
        />
      )}
    />
  );
};
