import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Mop } from 'assets/svg/mop.svg';
import { FlexBox, Typography } from 'components/atoms';

const Wrapper = styled(FlexBox)`
  align-items: center;
  background-color: #fff;
  bottom: 0;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
`;

export const Loader = () => {
  return (
    <Wrapper>
      <FlexBox alignItems="flex-end">
        <Mop style={{ marginRight: '1rem' }} />
        <Typography.Heading color="black" type="h1">
          Loading...
        </Typography.Heading>
      </FlexBox>
    </Wrapper>
  );
};
