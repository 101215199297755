import React, { Suspense, useEffect, useState, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from 'contexts/user';
import { useFirebaseApp } from 'reactfire';
import { Loader } from '../Loader';

const AuthorizedRoute = ({ component: Component, toggleAppointment, ...rest }) => {
  const firebase = useFirebaseApp();
  const userContext = useContext(UserContext);
  const [state, setState] = useState({
    pending: true,
    loggedIn: false,
  });
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (isMounted) {
        if (user) {
          userContext.setUser({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber ? user.phoneNumber : '',
          });
          setState({
            pending: false,
            loggedIn: true,
          });
        } else {
          setState({
            pending: false,
            loggedIn: false,
          });
        }
      }
    });

    return () => {
      setIsMounted(false);
      unsubscribe();
    };
    /* eslint-disable-next-line */
  }, [isMounted]);

  const { pending, loggedIn } = state;
  return pending ? (
    <Loader />
  ) : (
    <Route
      {...rest}
      render={renderProps =>
        loggedIn ? (
          <Suspense fallback={<Loader />}>
            <Component toggleAppointment={toggleAppointment} {...renderProps} />
          </Suspense>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: renderProps.location },
            }}
          />
        )
      }
    />
  );
};

AuthorizedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  toggleAppointment: PropTypes.func.isRequired,
};

export default AuthorizedRoute;
