import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import { Link } from 'react-router-dom';
import { FlexBox, Content, Typography } from 'components/atoms';
import logo from 'assets/svg/logo.svg';
import { ReactComponent as Facebook } from 'assets/svg/facebook.svg';
import { ReactComponent as Instagram } from 'assets/svg/instagram.svg';
import { isObjEmpty } from 'utils';
import { staticImages } from 'utils/assets';

const Wrapper = styled.footer`
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.7);
  background-position: bottom;
  background-image: url('${staticImages.footer}');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: auto;
  padding: 5rem 0;
`;

const SocialLink = styled.a`
  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

const Logo = styled.img`
  height: 10rem;
  margin-bottom: 2.5rem;
`;

export const Footer = ({ user, toggleModal }) => {
  return (
    <Wrapper>
      <Content>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 6 / 12]} mb={['5rem', '5rem', 0]} pr={[0, 0, '2rem', '5rem']}>
            <Logo src={logo} />

            <Typography.Paragraph color="white" size="big" style={{ marginBottom: '2.5rem' }}>
              Delivering Happiness Through Hygiene to Keep You Happy & Relaxed.
            </Typography.Paragraph>

            <FlexBox alignItems="flex-end">
              <SocialLink href="https://www.facebook.com/findmaidswestyorkshire/" aria-label="Facebook link">
                <Facebook />
              </SocialLink>

              <SocialLink href="https://www.instagram.com/findmaids/" aria-label="Instagram link">
                <Instagram />
              </SocialLink>
            </FlexBox>
          </Box>

          <Box width={[1, 1, 3 / 12]} mb={['5rem', '5rem', 0]} pr={[0, 0, '2rem']}>
            <Typography.Heading type="h3" style={{ marginBottom: '2.5rem' }} color="white">
              Company Link
            </Typography.Heading>
            <FlexBox as="ul" column style={{ marginBottom: '5rem' }}>
              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/about-us">
                  About Us
                </Typography.Paragraph>
              </ListItem>

              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/privacy-policy">
                  Privacy Policy
                </Typography.Paragraph>
              </ListItem>
            </FlexBox>

            <Typography.Heading type="h3" style={{ marginBottom: '2.5rem' }} color="white">
              Contact Us
            </Typography.Heading>

            <FlexBox as="ul" column>
              <ListItem>
                <Typography.Paragraph
                  as="a"
                  size="big"
                  color="white"
                  href="mailto:info@findmaids.co.uk"
                  style={{ wordBreak: 'break-word' }}
                >
                  info@findmaids.co.uk
                </Typography.Paragraph>
              </ListItem>

              <ListItem>
                <Typography.Paragraph as="a" size="big" color="white" href="tel:+441138730038">
                  +44 (0) 113 873 0038
                </Typography.Paragraph>
              </ListItem>
            </FlexBox>
          </Box>

          <Box width={[1, 1, 3 / 12]}>
            <FlexBox as="ul" column>
              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/pricing">
                  Pricing
                </Typography.Paragraph>
              </ListItem>

              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/newsletter">
                  Social Media/ Newsletter
                </Typography.Paragraph>
              </ListItem>

              {!isObjEmpty(user) ? (
                <ListItem>
                  <Typography.Paragraph as={Link} size="big" color="white" to="/dashboard">
                    My Account
                  </Typography.Paragraph>
                </ListItem>
              ) : (
                <ListItem>
                  <Typography.Paragraph style={{ cursor: 'pointer' }} size="big" color="white" onClick={toggleModal}>
                    My Account
                  </Typography.Paragraph>
                </ListItem>
              )}
              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/terms-and-conditions">
                  Terms
                </Typography.Paragraph>
              </ListItem>
              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/join-us">
                  Join The Crew
                </Typography.Paragraph>
              </ListItem>
              <ListItem>
                <Typography.Paragraph as={Link} size="big" color="white" to="/spread-the-love">
                  Spread The Love
                </Typography.Paragraph>
              </ListItem>
            </FlexBox>
          </Box>
        </Flex>
      </Content>
    </Wrapper>
  );
};

Footer.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
