import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader } from 'components/molecules';
import AuthorizedRoute from 'components/molecules/Dashboard/Route';
import AdminRoute from 'components/molecules/Admin/Route';
import Wrapper from 'components/organisms/Wrapper';

const About = lazy(() => import('pages/About'));
const Admin = lazy(() => import('pages/Admin'));
const Cleaner = lazy(() => import('pages/Cleaner'));
const Contact = lazy(() => import('pages/Contact'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Faq = lazy(() => import('pages/Faq'));
const Home = lazy(() => import('pages/Home'));
const JoinUs = lazy(() => import('pages/JoinUs'));
const NotFound = lazy(() => import('pages/NotFound'));
const Pricing = lazy(() => import('pages/Pricing'));
const Privacy = lazy(() => import('pages/Privacy'));
const Services = lazy(() => import('pages/Services'));
const Terms = lazy(() => import('pages/Terms'));

const Scroll = props => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {};
  }, [props.location]);

  return props.children;
};

Scroll.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const ScrollToTop = withRouter(Scroll);

const WrappedSwitch = withRouter(
  Wrapper(({ toggleAppointment }) => {
    return (
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Home toggleAppointment={toggleAppointment} {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/about-us"
            render={props => (
              <Suspense fallback={<Loader />}>
                <About {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/services"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Services toggleAppointment={toggleAppointment} {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/privacy-policy"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Privacy {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/contact-us"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Contact {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/faq"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Faq {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/join-us"
            render={props => (
              <Suspense fallback={<Loader />}>
                <JoinUs {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/pricing"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Pricing {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/terms-and-conditions"
            render={props => (
              <Suspense fallback={<Loader />}>
                <Terms {...props} />
              </Suspense>
            )}
          />
          <Route
            path="/spread-the-love"
            // eslint-disable-next-line no-return-assign
            render={props => {
              window.location.href = 'https://g.page/FindMaids/review';

              return (
                <Suspense fallback={<Loader />}>
                  <About {...props} />
                </Suspense>
              );
            }}
          />
          <Route
            path="/newsletter"
            // eslint-disable-next-line no-return-assign
            render={props => {
              window.location.href = 'https://blog.findmaids.co.uk';

              return (
                <Suspense fallback={<Loader />}>
                  <About {...props} />
                </Suspense>
              );
            }}
          />
          <AuthorizedRoute toggleAppointment={toggleAppointment} path="/dashboard" component={Dashboard} />
          {/* eslint-disable-next-line jsx-a11y/aria-role */}
          <AdminRoute path="/cleaner" component={Cleaner} role="cleaner" />
          {/* eslint-disable-next-line jsx-a11y/aria-role */}
          <AdminRoute path="/admin" component={Admin} role="admin" />
          <Route
            path="/*"
            render={props => (
              <Suspense fallback={<Loader />}>
                <NotFound {...props} />
              </Suspense>
            )}
          />
        </Switch>
      </ScrollToTop>
    );
  }),
);

const RouterComponent = () => {
  return (
    <Router>
      <WrappedSwitch />
    </Router>
  );
};

export default RouterComponent;
