export const CLEANING_LOCATION = {
  ALL: 'All',
  CLASSIC_CLEAN_BATHROOM: 'Bathroom (Classic Clean)',
  CLASSIC_CLEAN_BEDROOM: 'Bedroom (Classic Clean)',
  CLASSIC_CLEAN_KITCHEN: 'Kitchen (Classic Clean)',
  CLASSIC_CLEAN_LIVING_AREAS: 'Living Areas (Classic Clean)',
  DEEP_CLEAN_BATHROOM: 'Bathroom (Deep Clean)',
  DEEP_CLEAN_BEDROOM: 'Bedroom (Deep Clean)',
  DEEP_CLEAN_KITCHEN: 'Kitchen (Deep Clean)',
  DEEP_CLEAN_LIVING_AREAS: 'Living Areas (Deep Clean)',
};

export const CLEANING_TYPE = {
  both: { label: 'Deep and Classic Clean', value: 'both' },
  classic: { label: 'Classic clean', value: 'classic' },
  deep: { label: 'Deep Clean', value: 'deep' },
};

export const getCleaningLocationsDataForDropdown = () => {
  const res = [];
  Object.keys(CLEANING_LOCATION).forEach(v => {
    res.push({ label: CLEANING_LOCATION[v], value: CLEANING_LOCATION[v] });
  });

  return res;
};
