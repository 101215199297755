export const theme = {
  breakpoints: ['36em', '48em', '62em', '75em'],
  buttons: {
    small: {
      fontSize: '1.2rem',
      padding: '0.5rem 1.0rem',
    },
    normal: {
      fontSize: '1.4rem',
      height: '4.0rem',
      padding: '0.5rem 1.5rem',
    },
    large: {
      fontSize: '1.6rem',
      height: '4.5rem',
      padding: '1.4rem 3.0rem',
    },
  },
  colors: {
    black: '#000',
    dark: '#3F3D56',
    darkblue: '#3A5997',
    darkorange: '#FFA500',
    green: '#008000',
    grey: '#C4C4C4',
    lightblue: '#4285F4',
    lightgreen: '#3ACC6C',
    lightred: '#FF7185',
    midblue: '#5CAADE',
    offdark50: '#3f3d5699',
    pink: '#E75F7E',
    primary: '#E1AC1E',
    transparent: 'transparent',
    white: '#FFFFFF',
  },
  font: {
    align: {
      center: 'center',
      initial: 'initial',
      left: 'left',
      right: 'right',
    },
    size: {
      large: '3.0rem',
      big: '2.0rem',
      normal: '1.6rem',
      small: '1.4rem',
      tiny: '1.2rem',
      inherit: 'inherit',
    },
    weight: {
      '500': '500',
      bold: 'bold',
      medium: 'medium',
      normal: 'normal',
    },
  },
  heading: {
    desktop: {
      h1: '6.5rem',
      h2: '4.5rem',
      h3: '2.5rem',
    },
    mobile: {
      h1: '3.0rem',
      h2: '2.3rem',
      h3: '1.8rem',
    },
  },
};
