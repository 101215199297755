export const initUserTotalBookingsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('user_stats')
    .doc('total_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('user_stats')
          .doc('total_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating user_stats.total_bookings counter');
      console.log(err);
    });
};

export const initUserTotalSubscriptionsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('user_stats')
    .doc('total_subscriptions')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('user_stats')
          .doc('total_subscriptions')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating user_stats.total_subscriptions counter');
      console.log(err);
    });
};

export const initUserCanceledSubscriptionsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('user_stats')
    .doc('canceled_subscriptions')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('user_stats')
          .doc('canceled_subscriptions')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating user_stats.canceled_subscriptions counter');
      console.log(err);
    });
};

export const initUserCanceledBookingsCounter = (firebase, user) => {
  firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('user_stats')
    .doc('canceled_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('user_stats')
          .doc('canceled_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating user_stats.canceled_bookings counter');
      console.log(err);
    });
};

export const initUserCompletedBookingsCounter = (firebase, user) => {
  firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('user_stats')
    .doc('completed_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('user_stats')
          .doc('completed_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating user_stats.completed_bookings counter');
      console.log(err);
    });
};
