import React, { useState } from 'react';

const UserContext = React.createContext({});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    displayName: '',
    email: '',
    photoURL: '',
    phoneNumber: '',
  });

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
