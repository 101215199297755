import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexBox, Typography } from 'components/atoms';

const Image = styled.img`
  border-radius: 1rem;
  margin-right: 2rem;
  float: left;
  height: 15rem;
  width: 10rem;
  object-fit: cover;
  object-position: center;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    height: 18rem;
    width: 13rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    height: 22rem;
    width: 15rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    height: 30rem;
    width: 20rem;
  }
`;

const Wrapper = styled(FlexBox)`
  flex-direction: row;
`;

export const TeamMember = ({ image, name, role, summary }) => (
  <Wrapper>
    <Typography.Paragraph>
      <Image src={image} />
      <Typography.Paragraph size="large" style={{ marginBottom: '1rem' }}>
        {name}
      </Typography.Paragraph>

      <Typography.Paragraph size="big" style={{ marginBottom: '1.5rem' }} color="primary">
        {role}
      </Typography.Paragraph>
      <Typography.Paragraph color="offdark50">{summary}</Typography.Paragraph>
    </Typography.Paragraph>
  </Wrapper>
);

TeamMember.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};
