import styled, { css } from 'styled-components';

export const FlexBox = styled.div`
  align-items: ${props => props.alignItems};
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: ${props => props.justifyContent};
  margin-bottom: 0;

  ${props => {
    if (props.flex) {
      return css`
        flex: ${props.flex};
      `;
    }
  }}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
`;

FlexBox.defaultProps = {
  alignItems: 'initial',
  justifyContent: 'intial',
};
