import styled from 'styled-components';
import { Button } from 'components/atoms';

export const FixedButton = styled(Button)`
  bottom: 3rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 3rem;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    bottom: 7rem;
    font-size: 2rem;
    height: 6rem;
    padding: 2rem 5rem;
    position: fixed;
    right: 8rem;
  }
`;

FixedButton.defaultProps = {
  cornered: 'true',
  size: 'large',
};
