import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexBox, Typography } from 'components/atoms';

const Wrapper = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 1rem;
  display: inline-flex;
  padding: 1rem 1rem;
  flex-direction: column;
`;

const Price = styled(Typography.Paragraph)`
  color: ${props => props.theme.colors.white} !important;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const PriceTag = ({ price, suffix }) => (
  <Wrapper>
    <Price>￡{price}</Price>
    <Typography.Paragraph align="center" fontWeight="light" color="white">
      {suffix}
    </Typography.Paragraph>
  </Wrapper>
);

PriceTag.defaultProps = {
  price: '35',
  suffix: 'Per Clean',
};

PriceTag.propTypes = {
  price: PropTypes.string,
  suffix: PropTypes.string,
};
