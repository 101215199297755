import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'components/atoms';
import PropTypes from 'prop-types';

const Bubble = styled.div`
  background: #fff;
  border-radius: 1rem 1rem 1rem 0;
  bottom: 6rem;
  box-shadow: 0px 4px 54px rgba(225, 172, 30, 0.45);
  left: 5rem;
  max-width: 48rem;
  min-width: 20rem;
  opacity: 0;
  padding: 1.5rem;
  pointer-events: none;
  position: absolute;
  transition: all 0.2s;
  &:after {
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid #fff;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid #fff;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 100%;
    width: 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    bottom: 10rem;
    left: 9rem;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 6rem;

  &:hover {
    ${Bubble} {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    width: 10rem;
  }

  ${({ active }) =>
    active &&
    css`
      width: 100%;
      ${Bubble} {
        opacity: 1;
        pointer-events: auto;
        min-width: calc(100% - 10rem);
      }

      @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
        width: 100%;
      }
    `}
`;

const Avatar = styled.img`
  border-radius: 50%;
  box-shadow: 0px 4px 54px rgba(225, 172, 30, 0.42);
  height: 6rem;
  object-fit: cover;
  width: 6rem;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    height: 10rem;
    width: 10rem;
  }
`;

export const Testimonial = ({ active, avatar, className, name, testimonial }) => {
  return (
    <Wrapper className={className} active={active}>
      <Avatar src={avatar} alt={`${name} avatar`} />
      <Bubble>
        <Typography.Paragraph style={{ marginBottom: '0.5rem' }}>{name}</Typography.Paragraph>

        <Typography.Paragraph color="grey">{testimonial}</Typography.Paragraph>
      </Bubble>
    </Wrapper>
  );
};

Testimonial.propTypes = {
  active: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
};
