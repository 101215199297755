import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonTemplate } from './Button';

const HamburgerBar = styled.span`
  background: white;
  border-radius: 2px;
  content: '';
  display: block;
  height: 0.2rem;
  position: relative;
  transition: 0.3s all;
  width: 3rem;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const HamBurgerButton = styled(ButtonTemplate)`
  cursor: pointer;
  display: block;
  padding: 0;
  position: relative;

  ${({ active }) =>
    active &&
    css`
      ${HamburgerBar} {
        &:first-child {
          transform: rotate(45deg);
          top: 11px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          transform: rotate(-45deg);
          top: -5px;
        }
      }
    `}
`;

export const Hamburger = props => {
  return (
    <HamBurgerButton {...props} aria-label="Hamburger">
      <HamburgerBar />
      <HamburgerBar />
      <HamburgerBar />
    </HamBurgerButton>
  );
};
