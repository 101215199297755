import React, { Suspense, useEffect, useState, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserContext } from 'contexts/user';
import { Statuses } from 'utils';
import { useFirebaseApp } from 'reactfire';
import { Loader } from '../Loader';

const AdminRoute = ({ component: Component, role, toggleAppointment, ...rest }) => {
  const firebase = useFirebaseApp();
  const userContext = useContext(UserContext);
  const [state, setState] = useState({
    pending: true,
    loggedIn: false,
    redirect: '/',
  });
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (isMounted) {
        if (user) {
          userContext.setUser({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber ? user.phoneNumber : '',
          });

          if (role === 'admin') {
            firebase
              .firestore()
              .collection('admin')
              .doc(user.uid)
              .get()
              .then(doc => {
                if (doc.exists && doc.data().isAdmin) {
                  setState({
                    pending: false,
                    loggedIn: true,
                  });
                } else {
                  setState({
                    pending: false,
                    loggedIn: false,
                    redirect: '/dashboard',
                  });
                }
              });
          } else if (role === 'cleaner') {
            firebase
              .firestore()
              .collection('users')
              .doc(user.uid)
              .get()
              .then(doc => {
                if (doc.exists && doc.data().cleaner_status === Statuses.VERIFIED) {
                  setState({
                    pending: false,
                    loggedIn: true,
                  });
                } else {
                  setState({
                    pending: false,
                    loggedIn: false,
                    redirect: '/dashboard',
                  });
                }
              });
          }
        } else {
          setState({
            pending: false,
            loggedIn: false,
          });
        }
      }
    });

    return () => {
      setIsMounted(false);
      unsubscribe();
    };
    /* eslint-disable-next-line */
  }, [isMounted, role]);

  const { pending, loggedIn } = state;
  return pending ? (
    <Loader />
  ) : (
    <Route
      {...rest}
      render={renderProps =>
        loggedIn ? (
          <Suspense fallback={<Loader />}>
            <Component toggleAppointment={toggleAppointment} {...renderProps} />
          </Suspense>
        ) : (
          <Redirect
            to={{
              pathname: state.redirect,
              state: { from: renderProps.location },
            }}
          />
        )
      }
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  role: PropTypes.string.isRequired,
  toggleAppointment: PropTypes.func.isRequired,
};

export default AdminRoute;
