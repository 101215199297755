import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';
import PropTypes from 'prop-types';
import { FlexBox, Typography } from 'components/atoms';
import { Checkbox } from 'components/atoms/Form/Checkbox';

const DropdownWrapper = styled.div`
  cursor: pointer;
  padding: 0;
  position: relative;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const DropdownPlaceholder = styled(FlexBox)`
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 0 1.5rem;
  svg {
    @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
      transition: 0.1s all;
    }
  }
  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const DropdownMenu = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  max-height: 25rem;
  min-width: 12rem;
  opacity: 0;
  overflow-y: scroll;
  pointer-events: none;
  position: absolute;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    transition: all 0.2s;
  }

  ${({ position }) => {
    const [x, y] = position.split(' ');
    return (
      position &&
      css`
        ${x}: 100%;
        ${y}: 0;
      `
    );
  }}

  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      pointer-events: auto;
      z-index: 1;

      @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
        transition: all 0.1s;
      }
    `}
`;

export const DropdownOption = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1rem 2rem;
  user-select: none;

  ${Checkbox} {
    width: 1.4rem;
    height: 1.4rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    transition: all 0.1s;
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: ${props => props.theme.colors.primary};
    }
  }
`;

export const Dropdown = forwardRef(
  (
    {
      className,
      closeOnBlur,
      data,
      defaultOpen,
      disabled,
      menuStyle,
      multiple,
      onChange,
      placeholder,
      position,
      render,
      style,
      value,
    },
    ref,
  ) => {
    const node = useRef();
    const [open, setOpen] = useState(defaultOpen);
    const [option, setOption] = useState(null);

    const handleChange = val => {
      setOption(val);
      onChange(val.value);
      if (!multiple) {
        setOpen(false);
      }
    };

    const toggleDropdown = () => {
      if (!disabled) setOpen(v => !v);
    };

    const handleClick = e => {
      if (closeOnBlur && !node.current.contains(e.target)) {
        setOpen(false);
      }
    };

    useImperativeHandle(ref, () => ({
      isOpen() {
        return open;
      },
      openDropdown() {
        if (!disabled) setOpen(true);
      },
      closeDropdown() {
        if (!disabled) setOpen(false);
      },
    }));

    useEffect(() => {
      document.addEventListener('mousedown', handleClick);
      return () => {
        document.removeEventListener('mousedown', handleClick);
      };
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      const newOption = data.find(x => x.value === value);
      setOption(newOption);

      return () => {};
    }, [data, value]);

    const renderPlaceholder = () => {
      const placeholderNode = (children, color) => (
        <Typography.Paragraph style={{ userSelect: 'none' }} color={color}>
          {children}
        </Typography.Paragraph>
      );
      if (multiple && value.length) {
        return placeholderNode(`${value.length} Selections`, 'dark');
      }
      if (option && option.label) {
        return placeholderNode(option.label, 'dark');
      }
      return placeholderNode(placeholder, 'grey');
    };

    return (
      <DropdownWrapper ref={node} style={style} className={className}>
        {render ? (
          render({ open, toggleDropdown })
        ) : (
          <DropdownPlaceholder open={open} onClick={toggleDropdown}>
            {renderPlaceholder()}
            <CaretDown width={12} />
          </DropdownPlaceholder>
        )}

        <DropdownMenu open={open} position={position} style={menuStyle}>
          {data.map(dataOption => (
            <DropdownOption key={dataOption.value} onClick={() => handleChange(dataOption)}>
              {multiple && <Checkbox box checked={value && value.includes(dataOption.value)} />} {dataOption.label}
            </DropdownOption>
          ))}
        </DropdownMenu>
      </DropdownWrapper>
    );
  },
);

Dropdown.defaultProps = {
  className: '',
  closeOnBlur: true,
  defaultOpen: false,
  data: [],
  disabled: false,
  menuStyle: {},
  multiple: false,
  onChange: () => {},
  position: 'left',
  render: null,
  style: {},
};

DropdownMenu.defaultProps = {
  position: 'top left',
};

Dropdown.propTypes = {
  className: PropTypes.string,
  closeOnBlur: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  defaultOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  menuStyle: PropTypes.objectOf(PropTypes.any),
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  position: PropTypes.string,
  render: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.any,
};
