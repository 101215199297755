import React, { useEffect, useState } from 'react';
import { useFirebaseApp } from 'reactfire';
import styled from 'styled-components';
import { Footer, Nav } from 'components/molecules';
import { FixedButton } from 'components/atoms';
import AuthModal from './AuthModal';
import ApppointmentModal from './AppointmentModal';

const PageContent = styled.div`
  display: flex;
  flex-direction: column
  min-height: 100vh;
`;

const Wrapper = WrappedComponent => {
  const WithStateComponent = props => {
    const firebase = useFirebaseApp();

    const [aptModal, setAptModal] = React.useState(false);
    const [showLayout, setShowLayout] = React.useState(true);
    const [modal, setModal] = React.useState(false);
    const toggleModal = () => setModal(v => !v);
    const toggleAppointment = () => {
      setAptModal(v => !v);
      firebase.analytics().logEvent('booking_started');
    };
    const [user, setUser] = useState({});

    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
          setUser(firebaseUser);
        } else {
          setUser({});
        }
      });

      return () => {
        unsubscribe();
      };
    }, [firebase]);

    useEffect(() => {
      const path = props.history.location.pathname;

      if (path.includes('/cleaner') || path.includes('/admin')) {
        setShowLayout(false);
      } else {
        setShowLayout(true);
      }
    }, [props.history.location.pathname]);

    return showLayout ? (
      <PageContent>
        <Nav user={user} toggleModal={toggleModal} />
        <WrappedComponent changeLayout={setShowLayout} toggleAppointment={toggleAppointment} {...props} />
        <Footer user={user} toggleModal={toggleModal} />
        <FixedButton onClick={toggleAppointment}>Book Now</FixedButton>
        <ApppointmentModal isOpen={aptModal} toggle={toggleAppointment} toggleAuthModal={toggleModal} />
        <AuthModal isOpen={modal} toggle={toggleModal} />
      </PageContent>
    ) : (
      <WrappedComponent changeLayout={setShowLayout} {...props} />
    );
  };

  return WithStateComponent;
};

export default Wrapper;
