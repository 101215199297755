import styled from 'styled-components';
import { ReactComponent as MoreIcon } from 'assets/svg/menu.svg';

const Items = styled.div`
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
`;

const HoverMenu = styled.div`
  display: inline-block;
  position: relative;

  &:hover {
    ${Items} {
      opacity: 1;
      pointer-events: auto;
      top: ${props => props.top};
      z-index: 2;
    }
  }
`;

const Item = styled.p`
  cursor: pointer;
  display: block;
  min-width: 14rem;
  padding: 1rem 1.5rem;
  text-decoration: none;
  transition: all 0.1s;
  white-space: nowrap;

  &,
  &:visited {
    color: ${props => props.theme.colors[props.color]};
    background: ${props => props.theme.colors.white};
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }
`;

HoverMenu.defaultProps = {
  top: '50%',
};

Item.defaultProps = {
  color: 'drak',
};

MoreIcon.defaultProps = {
  style: { cursor: 'pointer' },
};

HoverMenu.List = Items;
HoverMenu.Item = Item;
HoverMenu.Icon = MoreIcon;

export { HoverMenu };
