import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'GlobalStyle';
import { NotificationProvider } from 'contexts/notification';
import { UserProvider } from 'contexts/user';
import { ToastContainer } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';
import Router from './Router';
import { theme } from './theme';

const App = () => {
  return (
    <React.StrictMode>
      <UserProvider>
        <NotificationProvider>
          <ThemeProvider theme={theme}>
            <>
              <GlobalStyle />
              <Router />
              <ToastContainer />
              <CookieConsent>
                In order to deliver a personalised, responsive service and improve your experience on the site, we track
                and store information about how you interact with the interface. This is done using cookies. These
                cookies are completely safe and secure and will never contain any sensitive information. If you continue
                to use the site, this means you consent with this collection of cookies. If you do not consent, please
                leave the site immediately. For more information please refer to our{' '}
                <a href="/privacy-policy">privacy policy</a>.
              </CookieConsent>
            </>
          </ThemeProvider>
        </NotificationProvider>
      </UserProvider>
    </React.StrictMode>
  );
};

export default App;
