import styled, { css } from 'styled-components';

export const Textarea = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: ${props => props.backgroundColor || `${props.theme.colors.primary}18`};
  border-radius: 0.5rem;
  border: none;
  color: ${props => props.theme.colors.dark};
  display: block;
  font-size: 1.6rem;
  height: 7.5rem;
  margin: 0;
  outline: none;
  padding: 1.5rem;
  width: 100%;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${props => props.theme.colors.grey};
  }

  ${props => {
    if (props.addonPosition) {
      return css`
        padding-${props.addonPosition}: 0.5rem;
      `;
    }
  }}
`;
