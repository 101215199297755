import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as queryString from 'query-string';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as HelpIcon } from 'assets/svg/help.svg';
import { ReactComponent as BinIcon } from 'assets/svg/bin.svg';
import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';
import { ReactComponent as InformationIcon } from 'assets/svg/information.svg';
import { toast } from 'react-toastify';
import { Button, Form, Typography, OutlineButton } from 'components/atoms';
import {
  concatAddresses,
  supportedCountries,
  bookingTimes,
  supportedCurrencies,
  Statuses,
  formatTime,
  getHostExtension,
} from 'utils';
import { toastr } from 'utils/toast';
import { useFirebaseApp } from 'reactfire';
import { Modal } from 'components/molecules/Modal';
import { PREMIUM_SERVICES } from 'data/premiumServices';
import { SERVICE_PRICES_BY_COUNTRY } from 'data/prices';
import { FREQUENCY_RATES } from 'data/frequencyRates';
import { CLEANING_LOCATION, CLEANING_TYPE, getCleaningLocationsDataForDropdown } from 'data/cleaningTypes';
import {
  Section,
  SectionTitle,
  RoomWrapper,
  List,
  ListItem,
  SubSection,
  CheckoutItem,
  CheckoutBox,
  CheckoutSubItem,
  DiscountBox,
  TooltipBody,
  TooltipWrapper,
} from './styled';

const frequencies = [
  { label: 'One-off', value: 'one_off' },
  { label: 'Weekly (save 15%)', value: 'weekly' },
  { label: 'Forthnightly (save 10%)', value: 'forthnightly' },
  { label: 'Monthly (save 5%)', value: 'monthly' },
];

const ApppointmentModal = ({ isOpen, toggle, toggleAuthModal }) => {
  const firebase = useFirebaseApp();
  const [cleanType, setCleanType] = useState('');
  const [cleaningLocations, setCleaningLocations] = useState([]);
  const [additionalRequests, setAdditionalRequests] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const [sections, setSections] = useState([]);
  const [premiumServices, setPremiumServices] = useState([]);
  const params = queryString.parse(window.location.search);
  const availableBookingTimes = bookingTimes;
  const [user, setUser] = useState({});
  const [bookingPending, setBookingPending] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [addressMap, setAddressMap] = useState({});
  const [shouldAddNewAddress, setShouldAddNewAddress] = useState(false);
  const [cleaningTypes, setCleaningTypes] = useState([]);
  const [quantity, setQuantity] = useState({});
  const [discountCode, setDiscountCode] = useState('');
  const [discountPrice, setDiscountPrice] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({
    frequency: 'one_off',
    dateTime: moment()
      .add(1, 'day')
      .set('hour', 8)
      .set('minutes', 0)
      .toDate(),
    address: {
      isDefault: false,
      addressName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      county: '',
      postcode: '',
      country: '',
      phone: '',
    },
    extraDetails: '',
  });
  const [infoModal, setInfoModal] = useState(false);

  const toggleInfoModal = () => setInfoModal(v => !v);

  const toggler = () => {
    if (bookingPending) {
      return;
    }

    toggle();
  };

  const toggleCompleted = () => setCompleted(v => !v);

  if (params.booking_id_successful) {
    if (showToast && !toast.isActive(params.booking_id_successful)) {
      toastr('Your cleaning appointment was successfully booked.', 'success', {
        toastId: params.booking_id_successful,
      });
      setShowToast(false);
    }
  }

  if (params.booking_id_cancelled) {
    if (showToast && !toast.isActive(params.booking_id_cancelled)) {
      toastr(
        'There was a problem booking your cleaning appointment. Kindly try again and if the problem persists, please contact support.',
        'error',
        { toastId: params.booking_id_cancelled },
      );
      setShowToast(false);
    }
  }

  const onSectionSelect = value => {
    const { ALL } = CLEANING_LOCATION;

    if (value === ALL) {
      const filteredTypes = cleaningLocations.filter(x => x.value !== CLEANING_LOCATION.ALL);

      const newQuantity = filteredTypes.reduce((acc, val) => {
        acc[val.value] = 1;
        return acc;
      }, {});

      setQuantity({ ...newQuantity, ...quantity });
      setSections(filteredTypes.map(v => v.label));
    } else if (!sections.includes(value)) {
      // add a new cleaning type
      setSections([...sections, value]);
      setQuantity({ ...quantity, [value]: 1 });
    } else if (sections.includes(value)) {
      // delete an unchecked cleaning type
      delete quantity[value];
      setQuantity({ ...quantity });

      setSections(sections.filter(x => x !== value));
    }
  };

  const onQuantityChange = (name, value) => {
    setQuantity(v => ({
      ...v,
      [name]: value,
    }));
  };

  const onQuantityRemove = name => {
    setSections(sections.filter(x => x !== name));
  };

  const renderQuantity = () => {
    const node = name => (
      <RoomWrapper>
        <Box width={[4 / 12, 2 / 12]} px="1rem">
          <Typography.Text>{name}</Typography.Text>
        </Box>
        <Box width={[7 / 12, 9 / 12]} px="1rem">
          <Form.Range value={quantity[name] || 1} onChange={({ target: { value } }) => onQuantityChange(name, value)} />
        </Box>
        <Box width={1 / 12} px="1rem">
          <BinIcon onClick={() => onQuantityRemove(name)} style={{ cursor: 'pointer' }} />
        </Box>
      </RoomWrapper>
    );

    return sections.map(v => node(v));
  };

  const renderCheckoutSubItem = item => {
    let { country } = bookingDetails.address;
    if (!country) {
      country = getHostExtension() === 'ie' ? 'IE' : 'UK';
    }

    const node = (name, price) => (
      <CheckoutSubItem>
        <Typography.Paragraph weight="500">{name}</Typography.Paragraph>
        <Typography.Paragraph>{price}</Typography.Paragraph>
      </CheckoutSubItem>
    );

    const getPrice = value => {
      let price = SERVICE_PRICES_BY_COUNTRY[country][value.cleanType] * value.qty;

      if (bookingDetails.frequency !== 'one_off') {
        price *= 1 - FREQUENCY_RATES[bookingDetails.frequency];
      }

      const currency = supportedCurrencies[country];

      return currency + price;
    };

    return node(item.cleanType, getPrice(item));
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(u => {
      if (u) {
        setUser(u);
      } else {
        setUser({});
      }
    });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  useEffect(() => {
    if (user.uid) {
      const unsubscribe = firebase
        .firestore()
        .collection('addresses')
        .where('userId', '==', user.uid ? user.uid : ' ')
        .onSnapshot(
          querySnapshot => {
            const userAddressList = [];
            const tempAddressMap = {};

            querySnapshot.forEach(doc => {
              userAddressList.push({ label: concatAddresses(doc.data()), value: doc.id });
              tempAddressMap[doc.id] = { ...doc.data(), addressId: doc.id };
            });

            setAddressMap(tempAddressMap);
            setAddresses(userAddressList);
          },
          error => {
            console.log("Can't load saved addresses");
            console.log(error);
          },
        );

      return () => {
        unsubscribe();
      };
    }

    return () => {};
  }, [user, firebase]);

  useEffect(() => {
    if (isOpen) {
      ReactTooltip.rebuild();
    }
  }, [isOpen]);

  /**
   * Filter the cleaning locations based on the selected clean type
   */
  useEffect(() => {
    const temp = getCleaningLocationsDataForDropdown();
    if (cleanType === CLEANING_TYPE.both.value) {
      setCleaningLocations(temp);
    }

    if (cleanType === CLEANING_TYPE.classic.value) {
      setCleaningLocations(
        temp.filter(v => {
          return v.value.indexOf('Classic') > 0 || v.value === CLEANING_LOCATION.ALL;
        }),
      );
    }

    if (cleanType === CLEANING_TYPE.deep.value) {
      setCleaningLocations(
        temp.filter(v => {
          return v.value.indexOf('Deep') > 0 || v.value === CLEANING_LOCATION.ALL;
        }),
      );
    }
  }, [cleanType]);

  const toggleFullAddress = () => {
    setShouldAddNewAddress(v => !v);

    // empty the address form to allow the user enter an address
    setBookingDetails(prev => {
      return {
        ...prev,
        address: {
          addressId: 'NOT_SET',
          isDefault: false,
          addressName: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          county: '',
          postcode: '',
          country: '',
          phone: '',
        },
      };
    });
  };

  const cannotBookAppointment = () => {
    // user can not book appointment if they are yet to select a clean type
    if (
      cleaningTypes.filter(v => {
        return v.cleanType === '';
      }).length > 0
    ) {
      return true;
    }

    // user can not book appointment if thier selected time is not between 8am - 8pm
    if (
      !moment(bookingDetails.dateTime).isBetween(
        moment(bookingDetails.dateTime)
          .set('hour', 7)
          .set('minutes', 59),
        moment(bookingDetails.dateTime)
          .set('hour', 20)
          .set('minutes', 1),
      )
    ) {
      return true;
    }

    // user can not book appointment if their selected day is before tomorrow or after 6 months from now
    if (
      moment(bookingDetails.dateTime).isBefore(moment()) ||
      moment(bookingDetails.dateTime).isAfter(moment().add(6, 'months'))
    ) {
      return true;
    }

    // user can not book appointment if key address fields are missing
    if (
      !bookingDetails.address.addressLine1 ||
      !bookingDetails.address.city ||
      !bookingDetails.address.postcode ||
      !bookingDetails.address.phone ||
      !bookingDetails.address.country
    ) {
      return true;
    }

    return false;
  };

  const calculatePrice = () => {
    const country = bookingDetails.address.country ? bookingDetails.address.country : 'UK';
    let cost = 0;

    cost = cleaningTypes
      .filter(v => {
        return v.cleanType.length > 0;
      })
      .map(v => {
        return SERVICE_PRICES_BY_COUNTRY[country][v.cleanType] * v.qty;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);

    if (bookingDetails.frequency && bookingDetails.frequency !== 'one_off') {
      cost -= cost * FREQUENCY_RATES[bookingDetails.frequency];
    }

    return cost;
  };

  const saveBooking = () => {
    if (!firebase.auth().currentUser) {
      toggleAuthModal();
    } else {
      if (!window.Stripe) {
        toastr('Failed to initialise payment gateway. Please reload your browser.', 'error');
        return;
      }

      setBookingPending(true);

      const data = {
        bookingDetails,
        cleaningTypes,
        price: calculatePrice() - discountPrice,
        currency: supportedCurrencies[bookingDetails.address.country],
        status: Statuses.PENDING,
        userId: firebase.auth().currentUser.uid,
        userEmail: firebase.auth().currentUser.email,
        displayName: firebase.auth().currentUser.displayName,
        photoURL: firebase.auth().currentUser.photoURL,
        lastUpdated: moment().toDate(),
        checkout_success_url: `${window.location.origin + window.location.pathname}?booking_id_successful=`,
        checkout_cancel_url: `${window.location.origin + window.location.pathname}?booking_id_cancelled=`,
        discountCode: discountCode.toUpperCase(),
      };

      if (bookingDetails.frequency === 'one_off') {
        firebase
          .firestore()
          .collection('temp_bookings')
          .add(data)
          .then(doc => {
            const unsubscribe = firebase
              .firestore()
              .collection('temp_bookings')
              .doc(doc.id)
              .onSnapshot(booking => {
                if (booking.data().status === Statuses.CHECKOUT_SESSION_CREATED) {
                  firebase
                    .firestore()
                    .collection('settings')
                    .doc('stripe_api_publishable_key')
                    .get()
                    .then(d => {
                      const stripe = window.Stripe(d.data().value);

                      return stripe
                        .redirectToCheckout({
                          sessionId: booking.data().stripe_checkout_session_id,
                        })
                        .then(result => {
                          if (result.error.message) {
                            throw new Error(result.error.message);
                          }

                          setBookingPending(false);
                          unsubscribe();
                          toggle();
                        });
                    });
                }
              });
          })
          .catch(err => {
            setBookingPending(false);
            toastr(err.message, 'error');
            console.log(err);
          });
      } else {
        firebase
          .firestore()
          .collection('subscriptions')
          .add(data)
          .then(sub => {
            const unsubscribe = firebase
              .firestore()
              .collection('subscriptions')
              .doc(sub.id)
              .onSnapshot(doc => {
                if (doc.data().status === Statuses.CHECKOUT_SESSION_CREATED) {
                  firebase
                    .firestore()
                    .collection('settings')
                    .doc('stripe_api_publishable_key')
                    .get()
                    .then(d => {
                      const stripe = window.Stripe(d.data().value);

                      return stripe
                        .redirectToCheckout({
                          sessionId: doc.data().stripe_checkout_session_id,
                        })
                        .then(result => {
                          if (result.error.message) {
                            throw new Error(result.error.message);
                          }

                          setBookingPending(false);
                          unsubscribe();
                          toggle();
                        });
                    });
                }
              });
          })
          .catch(err => {
            setBookingPending(false);
            toastr(err.message, 'error');
            console.log(err);
          });
      }
    }
  };

  const getSubtotal = () => {
    const country = bookingDetails.address.country ? bookingDetails.address.country : 'UK';
    const currency = supportedCurrencies[country];

    return currency + calculatePrice();
  };

  const getDiscountAmount = () => {
    const country = bookingDetails.address.country ? bookingDetails.address.country : 'UK';
    const currency = supportedCurrencies[country];

    return currency + discountPrice;
  };

  const getFinalTotal = () => {
    const country = bookingDetails.address.country ? bookingDetails.address.country : 'UK';
    const currency = supportedCurrencies[country];

    return currency + (calculatePrice() - discountPrice);
  };

  const getDiscount = code => {
    const c = code.toUpperCase();
    return firebase
      .firestore()
      .collection('discounts')
      .where('code', '==', c)
      .where('status', '==', Statuses.ACTIVE)
      .get()
      .then(querySnapshot => {
        let discount = false;
        querySnapshot.forEach(doc => {
          discount = doc.data();
        });

        if (!discount) {
          throw new Error('Invalid Discount code');
        }
        return discount;
      })
      .catch(error => {
        toastr('Invalid Discount Code', 'error');
        console.log(error);
      });
  };

  const checkIfDiscountHasBeenUsed = code => {
    if (firebase.auth().currentUser) {
      const c = code.toUpperCase();
      return firebase
        .firestore()
        .collection('discounts_used')
        .doc(firebase.auth().currentUser.uid + c)
        .get()
        .then(doc => {
          if (doc.data()) {
            throw new Error('Discount code has already been used by you.');
          }
          return false;
        })
        .catch(error => {
          toastr('Discount code has already been used by you.', 'error');
          console.log(error);
        });
    }

    toastr('Login to use discount code.', 'error');
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  };

  const applyDiscount = () => {
    if (discountPrice === 0) {
      checkIfDiscountHasBeenUsed(discountCode)
        .then(value => {
          if (value === undefined) {
            return false;
          }

          if (!value) {
            return getDiscount(discountCode);
          }
          return false;
        })
        .then(discount => {
          if (discount) {
            setDiscountPrice((discount.rate / 100) * calculatePrice());
          }
        });
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggler} size="lg">
        <Modal.Header>
          <Typography.Heading type="h2" style={{ display: 'inline-flex', alignItems: 'baseline' }}>
            Make An Appointment{' '}
            <HelpIcon
              width="2.5rem"
              height="2.5rem"
              style={{ marginLeft: '1rem', cursor: 'pointer' }}
              onClick={toggleInfoModal}
            />
          </Typography.Heading>
          <Modal.CloseIcon onClick={toggler} />
        </Modal.Header>

        <Modal.Body>
          <Form>
            {!checkout ? (
              <Section>
                <SubSection>
                  <SectionTitle>What Type of Clean Do You Need?</SectionTitle>
                  <Flex px="1rem" flexWrap="wrap">
                    <TooltipWrapper mr="2rem">
                      <Form.Radio
                        checked={cleanType === CLEANING_TYPE.deep.value}
                        onClick={() => setCleanType(CLEANING_TYPE.deep.value)}
                        label={CLEANING_TYPE.deep.label}
                      />
                      <InformationIcon data-tip="deep-clean" data-for="deep-clean" />
                      <ReactTooltip type="light" effect="float" data-tip="deep-clean" id="deep-clean">
                        <TooltipBody>
                          Deep cleans are an intensive thorough cleaning of your home, giving it the brand new smell and
                          feel
                        </TooltipBody>
                      </ReactTooltip>
                    </TooltipWrapper>
                    <TooltipWrapper mr="2rem">
                      <Form.Radio
                        checked={cleanType === CLEANING_TYPE.classic.value}
                        onClick={() => setCleanType(CLEANING_TYPE.classic.value)}
                        label={CLEANING_TYPE.classic.label}
                      />
                      <InformationIcon data-tip="classic-clean" data-for="classic-clean" />
                      <ReactTooltip type="light" effect="float" data-tip="classic-clean" id="classic-clean">
                        <TooltipBody>
                          Classic cleans are designed to help you keep your house a home by giving them that welcome
                          breath of fresh air at the right time.
                        </TooltipBody>
                      </ReactTooltip>
                    </TooltipWrapper>
                    <Box>
                      <Form.Radio
                        checked={cleanType === CLEANING_TYPE.both.value}
                        onClick={() => setCleanType(CLEANING_TYPE.both.value)}
                        label={CLEANING_TYPE.both.label}
                      />
                    </Box>
                  </Flex>
                </SubSection>

                {cleanType && (
                  <>
                    <SubSection>
                      <SectionTitle>Which Section(s) of Your House Do You Wish To Be Cleaned?</SectionTitle>
                      <Flex mb="2rem">
                        <Box px="1rem" width={[1, 1, 1 / 2]}>
                          <Form.Select
                            placeholder="Select options"
                            data={cleaningLocations}
                            onChange={v => onSectionSelect(v)}
                            value={sections}
                            multiple
                          />
                        </Box>
                      </Flex>

                      {renderQuantity()}
                    </SubSection>

                    {(sections.length && (
                      <>
                        <SubSection>
                          <SectionTitle>Do You Have Any Additional Requests or Needs?</SectionTitle>
                          <Flex mb="2.5rem" px="1rem">
                            <Form.Radio
                              checked={!additionalRequests}
                              onClick={() => setAdditionalRequests(false)}
                              label="No"
                            />

                            <Form.Radio
                              checked={additionalRequests}
                              onClick={() => setAdditionalRequests(true)}
                              label="Yes"
                            />
                          </Flex>
                          {additionalRequests && (
                            <>
                              <SectionTitle> What Additional Service(s) Will You Like to Add?</SectionTitle>
                              <Flex mb="2rem">
                                <Box px="1rem" width={[1, 1, 1 / 2]}>
                                  <Form.Select
                                    placeholder="Select options"
                                    data={PREMIUM_SERVICES}
                                    onChange={v => {
                                      setPremiumServices(prev => {
                                        if (prev.includes(v)) {
                                          return prev.filter(val => val !== v);
                                        }
                                        return [...prev, v];
                                      });
                                    }}
                                    value={premiumServices}
                                    multiple
                                  />
                                </Box>
                              </Flex>

                              <SectionTitle>Extra description</SectionTitle>
                              <Box px="1rem" width={[1]}>
                                <Form.Textarea placeholder="Write description" />
                              </Box>
                            </>
                          )}
                        </SubSection>

                        <Box px="1rem">
                          <Button
                            onClick={e => {
                              e.preventDefault();
                              setCheckout(true);

                              const temp = [];

                              sections.forEach(entry => {
                                temp.push({ cleanType: entry, qty: quantity[entry] });
                              });

                              Object.entries(premiumServices).forEach(entry => {
                                temp.push({ cleanType: entry[1], qty: 1 });
                              });

                              setCleaningTypes(temp);
                            }}
                          >
                            Continue To Checkout
                          </Button>
                        </Box>
                      </>
                    )) ||
                      null}
                  </>
                )}
              </Section>
            ) : (
              <>
                <Section>
                  <Flex flexWrap="wrap">
                    <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                      <Form.Group>
                        <Form.Label>Appointment Date*</Form.Label>
                        <Form.DayPicker
                          placeholder="DD/MM/YYYY"
                          // disable today and do not allow user to book more than 6 months into the future
                          disabledDays={{
                            before: moment()
                              .add(1, 'day')
                              .toDate(),
                            after: moment()
                              .add(6, 'months')
                              .toDate(),
                          }}
                          // select tomorrow by default
                          selectedDays={bookingDetails.dateTime}
                          value={bookingDetails.dateTime}
                          onDayChange={date => {
                            const newDateTime = moment(date);
                            setBookingDetails(prev => {
                              return {
                                ...prev,
                                dateTime: moment(bookingDetails.dateTime)
                                  .set('date', newDateTime.get('date'))
                                  .set('month', newDateTime.get('month'))
                                  .set('year', newDateTime.get('year'))
                                  .toDate(),
                              };
                            });
                          }}
                        />
                      </Form.Group>
                    </Box>

                    <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                      <Form.Group>
                        <Form.Label>Appointment Time*</Form.Label>
                        <Form.Select
                          placeholder=""
                          data={availableBookingTimes}
                          value={formatTime(bookingDetails.dateTime)}
                          onChange={val => {
                            const parts = val.split(':');
                            const hour = parts[0];
                            const minutes = parts[1];
                            const newDateTime = moment(bookingDetails.dateTime)
                              .set('hour', hour)
                              .set('minute', minutes)
                              .toDate();

                            setBookingDetails(prev => {
                              return { ...prev, dateTime: newDateTime };
                            });
                          }}
                        />
                      </Form.Group>
                    </Box>

                    <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                      <Form.Group>
                        <Form.Label>Frequency*</Form.Label>
                        <Form.Select
                          placeholder=""
                          data={frequencies}
                          value={bookingDetails.frequency}
                          onChange={val => {
                            setBookingDetails(prev => {
                              return { ...prev, frequency: val };
                            });
                          }}
                        />
                      </Form.Group>
                    </Box>
                  </Flex>
                </Section>

                <Section>
                  <Flex flexWrap="wrap">
                    {shouldAddNewAddress || addresses.length === 0 ? (
                      <>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>Address Name</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.addressName}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, addressName: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>

                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>Address Line 1*</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.addressLine1}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, addressLine1: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.addressLine2}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, addressLine2: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>City*</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.city}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, city: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>County</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.county}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, county: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>Postode*</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.postcode}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, postcode: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>Country*</Form.Label>
                            <Form.Select
                              placeholder=""
                              data={supportedCountries}
                              value={bookingDetails.address.country}
                              onChange={val => {
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, country: val } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>
                        <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem">
                          <Form.Group>
                            <Form.Label>Phone Number*</Form.Label>
                            <Form.Input
                              placeholder=""
                              value={bookingDetails.address.phone}
                              onChange={e => {
                                e.persist();
                                setBookingDetails(prev => {
                                  const a = prev.address;
                                  return { ...prev, address: { ...a, phone: e.target.value } };
                                });
                              }}
                            />
                          </Form.Group>
                        </Box>

                        {addresses.length !== 0 ? (
                          <Box width={[1, 1, 1 / 2]} mb="2.5rem" px="1rem" mt="-2.5rem">
                            <Typography.Text
                              color="lightred"
                              decoration="underline"
                              style={{ cursor: 'pointer', marginTop: '1rem' }}
                              onClick={toggleFullAddress}
                            >
                              Remove
                            </Typography.Text>
                          </Box>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <Box width={[1]} mb="2.5rem" px="1rem">
                        <Form.Group>
                          <Form.Label>Address</Form.Label>
                          <Form.Select
                            data={addresses}
                            value={
                              bookingDetails.address && bookingDetails.address.addressId !== 'NOT_SET'
                                ? bookingDetails.address.addressId
                                : ''
                            }
                            onChange={val => {
                              setBookingDetails(prev => {
                                return { ...prev, address: addressMap[val] };
                              });
                            }}
                            placeholder=""
                          />

                          <Typography.Text
                            color="primary"
                            decoration="underline"
                            style={{ cursor: 'pointer', marginTop: '1.5rem' }}
                            onClick={toggleFullAddress}
                          >
                            + Add New Address
                          </Typography.Text>
                        </Form.Group>
                      </Box>
                    )}
                  </Flex>
                </Section>
                <Section>
                  <CheckoutBox>
                    <CheckoutItem>
                      <Typography.Paragraph>Booking Type:</Typography.Paragraph>
                    </CheckoutItem>

                    <Box px="1rem" width={['100%', 'calc(100% - 13rem)']}>
                      <Typography.Paragraph>Individual Booking</Typography.Paragraph>
                    </Box>
                  </CheckoutBox>

                  <CheckoutBox>
                    <CheckoutItem>
                      <Typography.Paragraph>Cleaning Type:</Typography.Paragraph>
                    </CheckoutItem>

                    <Box px="1rem" width={['100%', 'calc(100% - 13rem)']}>
                      <Typography.Paragraph>{CLEANING_TYPE[cleanType].label}</Typography.Paragraph>
                    </Box>
                  </CheckoutBox>

                  <CheckoutBox>
                    <CheckoutItem>
                      <Typography.Paragraph>House details:</Typography.Paragraph>
                    </CheckoutItem>

                    <CheckoutItem>{cleaningTypes.map(renderCheckoutSubItem)}</CheckoutItem>
                  </CheckoutBox>

                  <Flex alignItems="flex-end" flexWrap="wrap">
                    <Box width={[1, 1 / 2]} px="1rem" order={[2, 1]}>
                      {discountPrice === 0 && (
                        <Form.InputGroup
                          addon={
                            <OutlineButton
                              noBorder
                              onClick={e => {
                                e.preventDefault();
                                applyDiscount();
                              }}
                            >
                              Apply
                            </OutlineButton>
                          }
                          addonPosition="right"
                          inputProps={{
                            placeholder: 'Discount Code',
                            onChange: v => {
                              setDiscountCode(v.target.value);
                            },
                            value: discountCode,
                            disabled: discountPrice > 0,
                          }}
                        />
                      )}
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      mb={['2rem', 0]}
                      order={[1, 2]}
                      px="1rem"
                      width={[1, 1 / 2]}
                    >
                      <DiscountBox>
                        <Typography.Paragraph style={{ marginBottom: '1rem' }}>{getSubtotal()}</Typography.Paragraph>
                        {discountPrice > 0 && (
                          <Typography.Paragraph>
                            <Typography.Text style={{ marginRight: '3rem', display: 'inline-flex' }}>
                              <BinIcon
                                width="1.5rem"
                                height="1.5rem"
                                style={{ marginRight: '2rem' }}
                                onClick={e => {
                                  e.preventDefault();
                                  setDiscountPrice(0);
                                  setDiscountCode('');
                                }}
                              />
                              Discount
                            </Typography.Text>
                            <Typography.Text weight="bold" style={{ color: 'lightgreen' }}>
                              -{getDiscountAmount()}
                            </Typography.Text>
                          </Typography.Paragraph>
                        )}
                      </DiscountBox>

                      <Typography.Paragraph weight="normal">
                        <Typography.Text style={{ marginRight: '3rem' }}>Total</Typography.Text>
                        <Typography.Text>{getFinalTotal()}</Typography.Text>
                      </Typography.Paragraph>
                    </Box>
                  </Flex>

                  <Box mt="3rem">
                    <Button
                      loading={bookingPending}
                      disabled={cannotBookAppointment() || bookingPending}
                      onClick={e => {
                        e.preventDefault();
                        saveBooking();
                      }}
                      style={{ marginRight: '1rem' }}
                    >
                      Complete Checkout
                    </Button>

                    <OutlineButton
                      cornered
                      size="large"
                      onClick={e => {
                        e.preventDefault();
                        setCheckout(false);
                      }}
                    >
                      Go Back
                    </OutlineButton>
                  </Box>
                </Section>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      <Modal isOpen={completed} toggle={toggleCompleted} size="lg">
        <Box display="flex" justifyContent="flex-end" px="2rem" py="1.5rem">
          <Modal.CloseIcon onClick={toggleCompleted} />
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" px="2rem" py="1.5rem">
          <Typography.Heading type="h2" style={{ marginBottom: '2rem' }} align="center">
            Appointment Booking Successful
          </Typography.Heading>

          <CheckIcon width="4rem" height="4rem" style={{ marginBottom: '2rem' }} />

          <OutlineButton size="large" cornered as={Link} to="#">
            View Appointments
          </OutlineButton>
        </Box>
      </Modal>

      <Modal isOpen={infoModal} toggle={toggleInfoModal} size="md">
        <Modal.Header>
          <Typography.Heading type="h3">How to book</Typography.Heading>
          <Modal.CloseIcon onClick={toggleInfoModal} />
        </Modal.Header>

        <Modal.Body>
          <List type="decimal">
            <ListItem>To book, select room type and cleaning type required i.e. classic or deep clean.</ListItem>

            <ListItem>
              Next, choose the amount of rooms you require this for. For example, if you require a deep clean to 2
              bathrooms, you’d select ‘bathroom (deep clean)’ and set quantity as ‘2’.
            </ListItem>
            <ListItem>
              Repeat steps 1 and 2 for any other rooms within the household you require this for.
              <List type="lower-latin">
                <ListItem>
                  For example, if you live in a 1 bedroom house with 2 bathrooms and 1 kitchen and you are booking a
                  deep clean for all rooms, your selections would be as follows:
                  <List type="lower-roman">
                    <ListItem>Bedroom(deep clean) + quantity = 1</ListItem>
                    <ListItem>Bathroom(deep clean) + quantity = 2</ListItem>
                    <ListItem>Kitchen(Deep clean) + quantity = 1</ListItem>
                    <ListItem>Living Room(Deep clean) + quantity = 1</ListItem>
                  </List>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>Choose your desired frequency</ListItem>
            <ListItem>Choose a date and time that suits you and be sure to add any additional requirements</ListItem>
          </List>
        </Modal.Body>
      </Modal>
    </>
  );
};

ApppointmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  toggleAuthModal: PropTypes.func.isRequired,
};

export default ApppointmentModal;
