import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import logo from 'assets/svg/logo.svg';
import PropTypes from 'prop-types';
import { Button, Content, FlexBox, Typography } from 'components/atoms';
import { isObjEmpty } from 'utils';

const Wrapper = styled(Content)`
  display: none;
  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    display: block;
    left: 0;
    position: fixed;
    top: 2rem;
    width: 100%;
    z-index: 1;
  }
`;

const Logo = styled.img`
  height: 5.5rem;
`;

Wrapper.defaultProps = {
  fluid: true,
};

const Menu = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.black};
  padding: 1rem 3rem;
  border-radius: 1rem;
  height: 6rem;
`;

const List = styled(FlexBox)`
  align-items: center;
`;

const ListItem = styled.li`
  list-style: none;
`;

const ListLink = styled(NavLink)`
  align-items: center;
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  padding: 1rem 2.5rem;
  text-decoration: none;
  transition: all 0.2s;

  &:visited {
    color: ${props => props.theme.colors.white};
  }

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  &.active {
    color: ${props => props.theme.colors.primary};
    position: relative;

    ${({ noline }) =>
      !noline &&
      css`
        &:after {
          background-color: ${props => props.theme.colors.primary};
          bottom: 0.5rem;
          content: '';
          height: 1px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: calc(100% - 5rem);
        }
      `}
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
`;

const DesktopNav = ({ toggleModal, user }) => {
  return (
    <>
      <Wrapper>
        <Menu>
          <Link to="/">
            <Logo src={logo} alt="Logo" />
          </Link>

          <List as="ul">
            <ListItem>
              <ListLink exact to="/">
                Home
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink to="/about-us">About</ListLink>
            </ListItem>
            <ListItem>
              <ListLink to="/services">Services</ListLink>
            </ListItem>
             <ListItem>
              <ListLink to="/pricing">Pricing</ListLink>
            </ListItem> 

            {!isObjEmpty(user) ? (
              <ListItem>
                <ListLink as={NavLink} to="/dashboard" noline="true">
                  <Avatar src={user.photoURL} /> <Typography.Text>{user.displayName}</Typography.Text>
                </ListLink>
              </ListItem>
            ) : (
              <ListItem>
                <Button onClick={toggleModal}>Login</Button>
              </ListItem>
            )}
          </List>
        </Menu>
      </Wrapper>
    </>
  );
};

DesktopNav.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DesktopNav;
