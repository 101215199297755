import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexBox } from '../FlexBox';
import { Checkbox } from './Checkbox';
import { Label } from './Label';

const Wrapper = styled(FlexBox)`
  align-items: center;
  cursor: pointer;
  ${Checkbox} {
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
  }

  ${Label} {
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0;
    user-select: none;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const Radio = ({ checked, label, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Checkbox checked={checked} rounded />
      <Label>{label}</Label>
    </Wrapper>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
