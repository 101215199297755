import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, Typography } from 'components/atoms';
import { Flex, Box } from 'rebass/styled-components';
import { ReactComponent as Facebook } from 'assets/svg/facebook.svg';
import { ReactComponent as Google } from 'assets/svg/google.svg';
import { initStats } from 'services/stats';
import { useFirebaseApp } from 'reactfire';
import { Modal } from '../molecules/Modal';

const AuthModal = withRouter(({ history, isOpen, toggle }) => {
  const firebase = useFirebaseApp();

  const onSuccess = user => {
    if (user) {
      toggle();

      // create cleaner & user stats
      initStats(firebase, user);

      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber ? user.phoneNumber : '',
            userId: user.uid,
          },
          { merge: true },
        );

      history.push('/dashboard');
    }
  };

  const facebookAuth = async () => {
    try {
      //  let fbAuth = firebase.auth.
      const { user } = await firebase.auth().signInWithPopup(new firebase.firebase_.auth.FacebookAuthProvider());
      onSuccess(user);
    } catch (e) {
      console.log(e);
    }
  };

  const googleAuth = async () => {
    try {
      const { user } = await firebase.auth().signInWithPopup(new firebase.firebase_.auth.GoogleAuthProvider());
      onSuccess(user);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <Modal.Header>
        <Typography.Heading type="h2">Login</Typography.Heading>
        <Modal.CloseIcon onClick={toggle} />
      </Modal.Header>
      <Modal.Body>
        <Flex flexWrap="wrap" mx="-1rem" mb="0.5rem">
          <Box width={[1, 1, 1 / 2]} px="1rem" mb="1rem">
            <Button color="darkblue" block onClick={facebookAuth}>
              <Facebook height={16} style={{ marginRight: '0.5rem' }} />
              Login with Facebook
            </Button>
          </Box>

          <Box width={[1, 1, 1 / 2]} px="1rem" mb="1rem">
            <Button color="lightblue" block onClick={googleAuth}>
              <Google height={16} style={{ marginRight: '0.5rem' }} />
              Login with Google
            </Button>
          </Box>
        </Flex>

        <Typography.Paragraph>
          By creating an account, I agree to FindMaid's{' '}
          <Typography.Text color="lightblue" as={Link} to="/terms-and-conditions">
            {' '}
            Terms and conditions
          </Typography.Text>{' '}
          and,{' '}
          <Typography.Text color="lightblue" as={Link} to="/privacy-policy">
            Privacy policy
          </Typography.Text>{' '}
          .
        </Typography.Paragraph>
      </Modal.Body>
    </Modal>
  );
});

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AuthModal;
