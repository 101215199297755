import {
  initUserTotalBookingsCounter,
  initUserTotalSubscriptionsCounter,
  initUserCanceledSubscriptionsCounter,
  initUserCanceledBookingsCounter,
  initUserCompletedBookingsCounter,
} from './userStats';
import {
  initCleanerTotalBookingsCounter,
  initCleanerCompletedBookingsCounter,
  initCleanerPendingBookingsCounter,
  initCleanerActiveBookingsCounter,
} from './cleanerStats';

export const initStats = (firebase, user) => {
  // check if stat exists and create it if it does not

  // user stats
  initUserTotalBookingsCounter(firebase, user);
  initUserTotalSubscriptionsCounter(firebase, user);
  initUserCanceledSubscriptionsCounter(firebase, user);
  initUserCanceledBookingsCounter(firebase, user);
  initUserCompletedBookingsCounter(firebase, user);

  // cleaner stats
  initCleanerTotalBookingsCounter(firebase, user);
  initCleanerCompletedBookingsCounter(firebase, user);
  initCleanerPendingBookingsCounter(firebase, user);
  initCleanerActiveBookingsCounter(firebase, user);
};
