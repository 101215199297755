import styled from 'styled-components';

export const Badge = styled.span`
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: 1rem;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
  margin: 0 0.3rem;
  padding: 0 0.4rem;
`;

Badge.defaultProps = {
  color: 'lightred',
};
