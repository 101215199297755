export const initCleanerTotalBookingsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('cleaner_stats')
    .doc('total_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('cleaner_stats')
          .doc('total_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating cleaner_stats.total_bookings counter');
      console.log(err);
    });
};

export const initCleanerCompletedBookingsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('cleaner_stats')
    .doc('completed_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('cleaner_stats')
          .doc('completed_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating cleaner_stats.completed_bookings counter');
      console.log(err);
    });
};

export const initCleanerPendingBookingsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('cleaner_stats')
    .doc('pending_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('cleaner_stats')
          .doc('pending_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating cleaner_stats.pending_bookings counter');
      console.log(err);
    });
};

export const initCleanerActiveBookingsCounter = (firebase, user) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(user.uid)
    .collection('cleaner_stats')
    .doc('active_bookings')
    .get()
    .then(docSnapshot => {
      if (!docSnapshot.exists) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('cleaner_stats')
          .doc('active_bookings')
          .set({ count: 0, userId: user.uid });
      }
    })
    .catch(err => {
      console.log('error creating cleaner_stats.active_bookings counter');
      console.log(err);
    });
};
