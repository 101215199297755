import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import { Content, FlexBox, Typography, Button } from 'components/atoms';
import PropTypes from 'prop-types';
import { isObjEmpty } from 'utils';

const Wrapper = styled(FlexBox)`
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: ${props => `url(${props.backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  height: ${props => props.height};
  justify-content: center;
  padding-bottom: 3rem;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    padding-bottom: 6rem;
  }
`;

const Heading = styled(Typography.Heading)`
  margin-bottom: 3rem;
`;

Heading.defaultProps = {
  type: 'h1',
  color: 'white',
};

export const HeroSection = ({ backgroundImage, button, height, title, children, ...rest }) => {
  return (
    <Wrapper backgroundImage={backgroundImage} height={height} {...rest}>
      <Content fluid>
        <Box width={[1, 10 / 12, 10 / 12, 7 / 12]}>
          {(title || children) && <Heading weight="bold">{title || children}</Heading>}
          {(!isObjEmpty(button) && (
            <FlexBox>
              <Button as={Link} to={button.link}>
                {button.text}
              </Button>
            </FlexBox>
          )) ||
            null}
        </Box>
      </Content>
    </Wrapper>
  );
};

HeroSection.defaultProps = {
  button: {},
  title: '',
  height: '50vh',
};

HeroSection.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  button: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
  height: PropTypes.string,
  title: PropTypes.string,
};
