import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FlexBox } from '../FlexBox';

export const Input = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearence: none;
  background-color: ${props => props.backgroundColor || `${props.theme.colors.primary}18`};
  border-radius: 0.5rem;
  border: none;
  color: ${props => props.theme.colors.dark};
  display: block;
  font-size: 1.6rem;
  height: 5rem;
  margin: 0;
  outline: none;
  padding: 1.5rem;
  width: 100%;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.12);
  }

  &::placeholder {
    color: ${props => props.theme.colors.grey};
  }

  ${props => {
    if (props.addonPosition) {
      return css`
        padding-${props.addonPosition}: 0.5rem;
      `;
    }
  }}

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        height: 3.5rem;
      `;
    }
  }}
`;

const InputGroupContainer = styled(FlexBox)`
  background-color: ${props => props.backgroundColor || `${props.theme.colors.primary}18`};
  border-radius: 0.5rem;
`;

const InputAddon = styled(FlexBox)`
  padding: 0 0.5rem;
  position: relative;
  width: 4rem;
`;

export const InputGroup = ({ addon, addonPosition, className, style, inputProps }) => (
  <InputGroupContainer className={className} row style={style}>
    {addonPosition === 'left' && (
      <InputAddon alignItems="center" justifyContent="center">
        {addon}
      </InputAddon>
    )}
    <Input backgroundColor="transparent" addonPosition={addonPosition} {...inputProps} />
    {addonPosition === 'right' && (
      <InputAddon alignItems="center" justifyContent="center">
        {addon}
      </InputAddon>
    )}
  </InputGroupContainer>
);

InputGroup.defaultProps = {
  addonPosition: 'left',
  className: '',
  style: {},
};

InputGroup.propTypes = {
  addon: PropTypes.node.isRequired,
  addonPosition: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  style: PropTypes.objectOf(PropTypes.any),
};
