export const PREMIUM_SERVICES = [
  { label: 'Lay The Bed (New bedding must be provided)', value: 'Lay The Bed (New bedding must be provided)' },
  { label: 'Wash & Put Away Dishes', value: 'Wash & Put Away Dishes' },
  { label: 'Clean and arrange food cabinets', value: 'Clean and arrange food cabinets' },
  { label: 'Clean inside and organise fridge', value: 'Clean inside and organise fridge' },
  { label: 'Clean interior windows', value: 'Clean interior windows' },
  { label: 'Oven Degreasing', value: 'Oven Degreasing' },
  {
    label: 'Iron/fold 10 garments (own iron must be supplied)',
    value: 'Iron/fold 10 garments (own iron must be supplied)',
  },
];

export const PREMIUM_SERVICES_PRICES = {
  'Lay The Bed (New bedding must be provided)': 5,
  'Wash & Put Away Dishes': 8,
  'Clean and arrange food cabinets': 5,
  'Clean inside and organise fridge': 5,
  'Clean interior windows': 12,
  'Oven Degreasing': 30,
  'Iron/fold 10 garments (own iron must be supplied)': 5,
};
