import styled from 'styled-components';
import { Checkbox } from './Checkbox';
import { DayPicker } from './DayPicker';
import { FormGroup } from './Group';
import { Input, InputGroup } from './Input';
import { Label } from './Label';
import { Radio } from './Radio';
import { Range } from './Range';
import { Select } from './Select';
import { Textarea } from './Textarea';

const Form = styled.form`
  margin: 0;
  padding: 0;
  width: 100%;
`;

Form.Checkbox = Checkbox;
Form.DayPicker = DayPicker;
Form.Group = FormGroup;
Form.Input = Input;
Form.InputGroup = InputGroup;
Form.Label = Label;
Form.Radio = Radio;
Form.Range = Range;
Form.Select = Select;
Form.Textarea = Textarea;

export { Form };
