import aboutBanner from '../assets/img/about-banner.jpg';
import aboutBanner2 from '../assets/img/about-banner-2.jpg';
import aboutBanner3 from '../assets/img/about-banner-3.jpg';
import aboutMission from '../assets/img/about-mission.jpg';
import bathroom from '../assets/img/bathroom.jpg';
import classicClean from '../assets/img/classic-clean.jpg';
import cleanBedroom from '../assets/img/clean-bedroom.jpg';
import cleanKitchen from '../assets/img/clean-kitchen.jpg';
import cleanLounge from '../assets/img/clean-lounge.jpg';
import cleanStairs from '../assets/img/clean-stairs.jpg';
import cleanToilet from '../assets/img/clean-toilet.jpg';
import contactBanner from '../assets/img/contact-banner.jpg';
import ctaBg from '../assets/img/cta-bg.jpg';
import deepClean from '../assets/img/deep-clean.jpg';
import deepCleanSummary from '../assets/img/deep-clean-summary.jpg';
import faqBanner from '../assets/img/faq-banner.jpg';
import footer from '../assets/img/footer.jpg';
import homeBanner from '../assets/img/home-banner.jpg';
import logo from '../assets/img/logo.png';
import logoDark from '../assets/img/logo-dark.png';
import overviewSummary from '../assets/img/overview-summary.jpg';
import proBg from '../assets/img/pro-bg.jpg';
import servicesBanner from '../assets/img/services-banner.jpg';

import portfolio1 from '../assets/img/portfolio/portfolio-1.jpg';
import portfolio2 from '../assets/img/portfolio/portfolio-2.jpg';
import portfolio3 from '../assets/img/portfolio/portfolio-3.jpg';
import portfolio4 from '../assets/img/portfolio/portfolio-4.jpg';

import dass from '../assets/img/testimonials/dass.jpg';
import endrit from '../assets/img/testimonials/endrit.jpg';
import flavio from '../assets/img/testimonials/flavio.jpg';
import molly from '../assets/img/testimonials/molly.jpg';
import rashid from '../assets/img/testimonials/rashid.jpg';
import ross from '../assets/img/testimonials/ross.jpg';
import santos from '../assets/img/testimonials/santos.jpg';
import silva from '../assets/img/testimonials/silva.jpg';
import voronin from '../assets/img/testimonials/voronin.jpg';

export const staticImages = {
  aboutBanner,
  aboutBanner2,
  aboutBanner3,
  aboutMission,
  bathroom,
  classicClean,
  cleanBedroom,
  cleanKitchen,
  cleanLounge,
  cleanStairs,
  cleanToilet,
  contactBanner,
  ctaBg,
  deepClean,
  deepCleanSummary,
  faqBanner,
  footer,
  homeBanner,
  logo,
  logoDark,
  overviewSummary,
  proBg,
  servicesBanner,
};

export const portfolioImages = {
  portfolio1,
  portfolio2,
  portfolio3,
  portfolio4,
};

export const testimonialImages = {
  dass,
  endrit,
  flavio,
  molly,
  rashid,
  ross,
  santos,
  silva,
  voronin,
};
