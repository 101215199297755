import styled, { css, keyframes } from 'styled-components';
import { darken } from 'polished';
import PropTypes from 'prop-types';

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const ButtonTemplate = styled.button`
  -webkit-appearance: none;
  align-items: center;
  background-color: transparent;
  border-radius: ${props => `${props.borderRadius}px`}
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: ${props => props.letterSpacing};
  line-height: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  white-space: nowrap;
  ${props => {
    if (props.size) {
      return css`
        font-size: ${props.theme.buttons[props.size].fontSize};
        font-weight: ${props.theme.buttons[props.size].fontWeight};
        height: ${props.theme.buttons[props.size].height};
        padding: ${props.theme.buttons[props.size].padding};
      `;
    }
  }}


  font-weight: ${props => (props.bold ? 'bold' : 'normal')};

  ${props => {
    if (props.block) {
      return css`
        width: 100%;
      `;
    }
  }}

  ${props => {
    if (props.rounded) {
      return css`
        border-radius: 500px;
      `;
    }
  }}


  &:disabled{
    cursor: not-allowed;
  }


  ${props => {
    if (props.cornered) {
      return css`
        border-radius: 1rem;
      `;
    }
  }}

  ${props => {
    if (props.loading) {
      return css`
        color: transparent !important;
        pointer-events: none;
        position: relative;

        &::after {
          content: '';
          animation: ${rotate} 500ms infinite linear;
          border: 2px solid #fff;
          border-radius: 50%;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          display: block;
          height: 1.2em;
          width: 1.2em;
          position: absolute;
          left: calc(50% - (1.2em / 2));
          top: calc(50% - (1.2em / 2));
          -webkit-transform-origin: center;
          transform-origin: center;
          position: absolute !important;
        }
      `;
    }
  }}
`;

export const Button = styled(ButtonTemplate)`
  background-color: ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: ${props => props.theme.colors.white};
  }

  ${props => {
    if (props.color === 'white') {
      return css`
        &,
        &:visited {
          color: ${props.theme.colors.black};
        }
      `;
    }
  }}


  ${({ color, faded, theme }) =>
    faded &&
    css`
      background-color: ${theme.colors[color]}30;

      &,
      &:visited {
        color: ${theme.colors[color]};
      }
    `}

  ${({ color, raised, theme }) =>
    raised &&
    css`
      box-shadow: 0 4px 0 ${darken(0.2, theme.colors[color])};

      &:hover {
        box-shadow: 0 2px 0 ${darken(0.2, theme.colors[color])};
        transform: translateY(2px);
      }

      &:active {
        box-shadow: none;
        transform: translateY(4px);
      }
    `}}

    &:disabled{
      background-color: #f0f0f0;
      
    }
`;

export const OutlineButton = styled(ButtonTemplate)`
  border: 1px solid ${props => props.theme.colors[props.color]};
  &,
  &:visited {
    color: ${props => props.theme.colors[props.color]};
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: none;
    `}

  &:disabled {
    border-color: #f0f0f0;
  }
`;

ButtonTemplate.defaultProps = {
  borderRadius: 0,
  letterSpacing: '0',
  size: 'normal',
};

ButtonTemplate.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large']).isRequired,
};

Button.propTypes = {
  color: PropTypes.string,
};
Button.defaultProps = {
  color: 'primary',
  cornered: true,
  size: 'large',
};

OutlineButton.propTypes = {
  color: PropTypes.string,
};

OutlineButton.defaultProps = {
  color: 'primary',
};
