import styled from 'styled-components';

export const HR = styled.hr`
  background: ${props => props.theme.colors.primary}14;
  border-radius: 60px;
  border: none;
  height: 0.5rem;
  margin: 2rem 0;
  width: 100%;
`;
