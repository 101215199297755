import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FlexBox, Typography } from 'components/atoms';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';

const Wrapper = styled(FlexBox)`
  background-color: ${props => props.theme.colors.primary}14;
  border-radius: 10px;
  padding: 2rem;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;

const Trigger = styled(FlexBox)`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  svg {
    transition: 0.1s all;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const Body = styled.div`
  height: 0;
  overflow: hidden
  padding: 0;
  transition: all 0.1s;
    ${({ isOpen }) =>
      isOpen &&
      css`
        padding-top: 1.5rem;
        height: auto;
      `};
`;

export const Accordion = ({ num, question, answer }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(v => !v);

  return (
    <Wrapper>
      {num && (
        <Typography.Paragraph color="offdark50" size="big" style={{ marginRight: '2rem' }}>
          {num}
        </Typography.Paragraph>
      )}
      <FlexBox column flex="1">
        <Trigger onClick={toggleOpen} isOpen={isOpen}>
          <Typography.Paragraph size="big">{question}</Typography.Paragraph>

          <CaretDown />
        </Trigger>
        <Body isOpen={isOpen}>
          <Typography.Paragraph color="offdark50" size="small">
            {answer}
          </Typography.Paragraph>
        </Body>
      </FlexBox>
    </Wrapper>
  );
};

Accordion.defaultProps = {
  num: '',
};

Accordion.propTypes = {
  num: PropTypes.string,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
