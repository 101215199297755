import { PREMIUM_SERVICES_PRICES } from './premiumServices';

export const SERVICE_PRICES_BY_COUNTRY = {
  UK: {
    'Bathroom (Classic Clean)': 15,
    'Bedroom (Classic Clean)': 15,
    'Kitchen (Classic Clean)': 15,
    'Living Areas (Classic Clean)': 15,
    'Bathroom (Deep Clean)': 45,
    'Bedroom (Deep Clean)': 45,
    'Kitchen (Deep Clean)': 45,
    'Living Areas (Deep Clean)': 45,
    ...PREMIUM_SERVICES_PRICES,
  },
  IE: {
    'Bathroom (Classic Clean)': 15,
    'Bedroom (Classic Clean)': 15,
    'Kitchen (Classic Clean)': 15,
    'Living Areas (Classic Clean)': 15,
    'Bathroom (Deep Clean)': 45,
    'Bedroom (Deep Clean)': 45,
    'Kitchen (Deep Clean)': 45,
    'Living Areas (Deep Clean)': 45,
    ...PREMIUM_SERVICES_PRICES,
  },
};
