import React from 'react';
import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, FlexBox, Typography } from 'components/atoms';
import { isObjEmpty } from 'utils';
import { useFirebaseApp } from 'reactfire';

const Wrapper = styled.div`
  background-color: #000;
  height: calc(100vh - 6rem);
  left: -100%;
  padding: 3rem;
  position: fixed;
  top: 6rem;
  transition: all 0.2s;
  width: 100%;
  z-index: 2;

  ${({ active }) =>
    active &&
    css`
      left: 0;
    `}
`;

const HR = styled.hr`
  background: ${props => props.theme.colors.primary}14;
  border-radius: 60px;
  border: none;
  height: 0.5rem;
  margin: 2rem 0;
  width: 100%;
`;

const List = styled(FlexBox)`
  flex-direction: column;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 3.5rem;
  }
`;

const ListLink = styled(Typography.Paragraph)`
  display: inline-flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;

  &,
  &:visited {
    color: ${props => props.theme.colors.white};
  }

  &:hover,
  &.active {
    color: ${props => props.theme.colors.primary};
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  box-shadow: 0 0 3px ${props => props.theme.colors.primary}12;
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
`;

export const Sidebar = ({ active, toggle, toggleModal, user }) => {
  const firebase = useFirebaseApp();
  return (
    <Wrapper active={active}>
      <List as="ul">
        <ListItem>
          <ListLink exact as={NavLink} to="/" onClick={toggle}>
            Home
          </ListLink>
        </ListItem>

        <ListItem>
          <ListLink as={NavLink} to="/about-us" onClick={toggle}>
            About
          </ListLink>
        </ListItem>

        <ListItem>
          <ListLink as={NavLink} to="/services" onClick={toggle}>
            Services
          </ListLink>
        </ListItem>

         <ListItem>
          <ListLink as={NavLink} to="/pricing" onClick={toggle}>
            Pricing
          </ListLink>
        </ListItem> 
      </List>
      <HR />

      <List as="ul">
        {!isObjEmpty(user) ? (
          <>
            <ListItem>
              <ListLink as={NavLink} to="/dashboard" noline="true" onClick={toggle}>
                <Avatar src={user.photoURL} /> <Typography.Text>{user.displayName}</Typography.Text>
              </ListLink>
            </ListItem>

            <ListItem>
              <ListLink
                as={Link}
                to="#"
                noline="true"
                onClick={() => {
                  console.log(firebase.auth());

                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      window.location.reload();
                      toggle();
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }}
              >
                Logout
              </ListLink>
            </ListItem>
          </>
        ) : (
          <ListItem>
            <Button
              onClick={() => {
                toggle();
                toggleModal();
              }}
            >
              Login
            </Button>
          </ListItem>
        )}

        <ListItem>
          <ListLink as={NavLink} to="/faq" onClick={toggle}>
            Need Help?
          </ListLink>
        </ListItem>
      </List>
    </Wrapper>
  );
};

Sidebar.propTypes = {
  active: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};
