import React from 'react';
import styled, { css } from 'styled-components';
import { Dropdown } from 'components/molecules';

const Select = styled(props => <Dropdown menuStyle={{ boxShadow: '0 1px 3px #3F3D5620' }} {...props} />)`
  background-color: ${props => props.backgroundColor || `${props.theme.colors.primary}18`};
  border-radius: 0.5rem;
  border: none;
  color: ${props => props.theme.colors.dark};
  height: 5rem;
  outline: none;
  padding: 0 1.5rem;
  position: relative;
  width: 100%;

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        height: 3.5rem;
      `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: rgba(128, 128, 128, 0.12);
      cursor: not-allowed;
    `}}
`;

Select.defaultProps = {
  closeOnBlur: true,
  position: 'top left',
};

export { Select };
