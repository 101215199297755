import React, { useState } from 'react';

const NotificationContext = React.createContext({});

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const markAsRead = id => {};
  const markAllAsRead = () => {};

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        markAsRead,
        markAllAsRead,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
