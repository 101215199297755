import React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/atoms';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
  overflow-x: auto;
  padding-bottom: 8rem;
  width: 100%;
`;

const TableComponent = styled.table`
  width: 100%;
`;

const Table = ({ children }) => (
  <TableWrapper>
    <TableComponent>
      <tbody>{children}</tbody>
    </TableComponent>
  </TableWrapper>
);

const Tr = styled.tr`
  align-items: center;
  border-bottom: 5px solid ${props => props.theme.colors.primary}14;
  flex-wrap: nowrap;
  flex: 1;
  position: relative;
  width: 100%;
`;

const Td = styled.td`
  border-bottom: 5px solid ${props => props.theme.colors.primary}14;
  min-width: ${props => props.minWidth};
  padding: 2rem;
`;

const Value = styled(Typography.Paragraph)`
  align-items: center;
  display: inline-flex;
  margin-bottom: 0.5rem;
`;

const Label = styled(Typography.Paragraph)`
  font-size: 1.4rem;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
`;

Td.defaultProps = {
  minWidth: '13rem',
};

Table.Avatar = Avatar;
Table.Tr = Tr;
Table.Td = Td;
Table.Value = Value;
Table.Label = Label;

export { Table };
