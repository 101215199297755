import styled from 'styled-components';

export const FloatingImage = styled.div`
  background-color: ${props => props.theme.colors[props.color]};
  border-radius: ${props => props.borderRadius};
  height: ${props => props.height};
  position: relative;
  width: ${props => props.width};

  &:after {
    background-image: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: ${props => props.borderRadius};
    background-position: ${props => props.position};
    bottom: 0.5rem;
    box-shadow: 0px 4px 14px rgba(63, 61, 86, 0.5);
    content: '';
    height: 100%;
    position: absolute;
    right: 0.5rem;
    width: 100%;

    @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
      bottom: ${props => props.bottom};
      right: ${props => props.right};
    }
  }
`;

FloatingImage.defaultProps = {
  borderRadius: '1rem',
  bottom: '2rem',
  color: 'dark',
  height: '37.5rem',
  position: 'initial',
  right: '7rem',
  width: '100%',
};
