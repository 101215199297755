import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FlexBox, Typography } from 'components/atoms';

const Icon = styled.div`
  margin-right: 1rem;
  min-width: 8rem;
  @media screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-right: 2rem;
    min-width: 10rem;
  }
`;

const Wrapper = styled(FlexBox)`
  margin-bottom: 3rem;
  flex-direction: row @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    margin-bottom: 0;
  }
`;

export const ServiceBox = ({ icon, subtitle, title }) => {
  return (
    <Wrapper>
      <Icon>
        <img src={icon} alt={`${title} illustration`} />
      </Icon>

      <FlexBox column>
        <Typography.Heading type="h3" style={{ marginBottom: '1rem' }}>
          {title}
        </Typography.Heading>
        <Typography.Paragraph color="offdark50" style={{ whiteSpace: 'pre-wrap' }}>
          {subtitle}
        </Typography.Paragraph>
      </FlexBox>
    </Wrapper>
  );
};

ServiceBox.propTypes = {
  icon: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
