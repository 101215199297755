import React from 'react';
import styled, { css } from 'styled-components';
import { ButtonTemplate, FlexBox, HR, Typography } from 'components/atoms';
import PropTypes from 'prop-types';

const Bar = (position = 'after') => css`
  &: ${position} {
    ${position === 'after' ? 'bottom: 0;' : 'top: 0;'};
    background: ${props => props.theme.colors.primary}14;
    border-radius: 60px;
    content: '';
    height: 0.5rem;
    left: 2rem;
    position: absolute;
    width: calc(100% - 4rem);
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 2rem 3.5rem;
    &: ${position} {
      left: 3.5rem;
      width: calc(100% - 7rem);
    }
  }
`;

const Content = styled.div`
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  margin: 6rem 1rem 6rem;
  transition: transform 0.3s ease-out;
  width: 100%;
  z-index: 10;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    margin-top: 2rem;
  }

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        max-width: 36rem;
      `;
    }
    if (size === 'md') {
      return css`
        max-width: 54rem;
      `;
    }
    if (size === 'lg') {
      return css`
        max-width: 72rem;
      `;
    }
  }}
`;

const Overlay = styled(FlexBox)`
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s;
`;

const Wrapper = styled(FlexBox)`
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: scroll;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.3s;
  visibility: hidden;
  z-index: 9;

  ${Content} {
    transform: translate(0, -50px);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;

      ${Content} {
        transform: translate(0, 0);
      }

      ${Overlay} {
        opacity: 1;
      }
    `}
`;

const ModalHeader = styled(FlexBox)`
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  position: relative;
  ${Bar('after')};
`;

const ModalBody = styled.div`
  padding: 1.5rem 2rem;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 2rem 3.5rem;
  }
`;

const ModalFooter = styled.div`
  padding: 1.5rem 2rem;
  position: relative;
  ${Bar('before')};
`;

const CloseButton = styled(ButtonTemplate)`
  cursor: pointer;
  display: block;
  position: relative;

  span {
    background: black;
    border-radius: 2px;
    content: '';
    display: block;
    height: 0.1rem;
    position: relative;
    transition: 0.3s all;
    width: 2rem;

    &:first-child {
      transform: rotate(45deg);
      top: 1px;
    }

    &:last-child {
      transform: rotate(-45deg);
      top: 0px;
    }
  }
`;

const SeparatorWrapper = styled(FlexBox)`
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  ${HR} {
    1
  }
`;

const CloseIcon = props => (
  <CloseButton {...props}>
    <span />
    <span />
  </CloseButton>
);

const Separator = ({ title }) => {
  return (
    <SeparatorWrapper>
      <HR />
      <Typography.Paragraph color="grey" align="center" style={{ flex: 1, padding: '0 1rem', whiteSpace: 'nowrap' }}>
        {title}
      </Typography.Paragraph>
      <HR />
    </SeparatorWrapper>
  );
};

const Modal = ({ children, size, isOpen, toggle, ...rest }) => (
  <Wrapper isOpen={isOpen} {...rest}>
    <Overlay onClick={toggle} />
    <Content size={size}>{children}</Content>
  </Wrapper>
);

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Separator = Separator;
Modal.CloseIcon = CloseIcon;

Modal.defaultProps = {
  size: 'sm',
};

Separator.propTypes = {
  title: PropTypes.string.isRequired,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export { Modal };
