import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FlexBox, Typography } from 'components/atoms';
import { ReactComponent as Badge } from 'assets/svg/badge.svg';
import { ReactComponent as Focus } from 'assets/svg/focus.svg';
import { ReactComponent as HourGlass } from 'assets/svg/hour-glass.svg';
import { ReactComponent as Kitchen } from 'assets/svg/kitchen.svg';
import { ReactComponent as Sofa } from 'assets/svg/sofa.svg';
import { ReactComponent as WallFrame } from 'assets/svg/wall-frame.svg';

const SvgWrapper = styled(FlexBox)`
  align-items: center;
  background-color: ${props => props.theme.colors.primary}14;
  border-radius: 50%;
  height: 6rem;
  justify-content: center;
  margin-right: 2rem;
  padding: 1.5rem;
  width: 6rem;
`;

const SvgComponent = ({ name, ...props }) => {
  const getSvg = () => {
    switch (name) {
      case 'badge':
        return <Badge {...props} />;

      case 'focus':
        return <Focus {...props} />;
      case 'hour-glass':
        return <HourGlass {...props} />;
      case 'kitchen':
        return <Kitchen {...props} />;
      case 'sofa':
        return <Sofa {...props} />;

      case 'wall-frame':
        return <WallFrame {...props} />;

      default:
        return <Badge {...props} />;
    }
  };

  return getSvg();
};

export const Commmitment = ({ icon, text }) => (
  <FlexBox>
    <SvgWrapper>
      <SvgComponent name={icon} />
    </SvgWrapper>

    <Typography.Paragraph size="big">{text}</Typography.Paragraph>
  </FlexBox>
);

Commmitment.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
