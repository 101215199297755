import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexBox, Hamburger } from 'components/atoms';
import logo from 'assets/svg/logo.svg';
import { Sidebar } from './Sidebar';

const Wrapper = styled(FlexBox)`
  align-items: center;
  background-color: ${props => props.theme.colors.black};
  height: 6rem;
  left: 0;
  padding: 1rem 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 4.5rem;
`;

const MobileNav = ({ toggleModal, user }) => {
  const [showMenu, setShowMenu] = React.useState(false);

  const toggleMenu = () => {
    setShowMenu(v => !v);
  };
  return (
    <>
      <Wrapper>
        <FlexBox style={{ flexBasis: '20%' }}>
          <Hamburger active={showMenu} onClick={toggleMenu} />
        </FlexBox>

        <FlexBox justifyContent="center" style={{ flexBasis: '60%' }}>
          <Logo src={logo} alt="Logo" />
        </FlexBox>
      </Wrapper>
      <Sidebar active={showMenu} toggle={toggleMenu} toggleModal={toggleModal} user={user} />
    </>
  );
};

MobileNav.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default MobileNav;
