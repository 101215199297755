import moment from 'moment';

export const isObjEmpty = obj => !Object.entries(obj).length;

export const supportedCountries = [
  { label: 'United Kingdom', value: 'UK' },
  { label: 'Ireland', value: 'IE' },
];
export const supportedCurrencies = { UK: '£', IE: '€' };
export const bookingTimes = [
  { label: '08:00 AM', value: '8:00' },
  { label: '09:00 AM', value: '9:00' },
  { label: '10:00 AM', value: '10:00' },
  { label: '11:00 AM', value: '11:00' },
  { label: '12:00 PM', value: '12:00' },
  { label: '01:00 PM', value: '13:00' },
  { label: '02:00 PM', value: '14:00' },
  { label: '03:00 PM', value: '15:00' },
  { label: '04:00 PM', value: '16:00' },
  { label: '05:00 PM', value: '17:00' },
  { label: '06:00 PM', value: '18:00' },
  { label: '07:00 PM', value: '19:00' },
  { label: '08:00 PM', value: '20:00' },
];

export const concatAddresses = a => {
  let res = '';
  res += a.addressLine1 ? a.addressLine1 : '';
  res += a.addressLine1 ? ', ' : '';
  res += a.addressLine2 ? a.addressLine2 : '';
  res += a.addressLine2 ? ', ' : '';
  res += a.city ? a.city : '';
  res += a.city ? ', ' : '';
  res += a.county ? a.county : '';
  res += a.county ? ', ' : '';
  res += a.postcode ? a.postcode : '';
  res += a.postcode ? ', ' : '';
  res += a.country
    ? supportedCountries.find(v => {
        return v.value === a.country;
      }).label
    : '';

  return res;
};

export const Statuses = {
  ACTIVE: 'ACTIVE',
  ACCEPTED: 'ACCEPTED',
  ASSIGNED: 'ASSIGNED',
  BOOKING_CREATED: 'BOOKING_CREATED',
  CANCELED: 'CANCELED',
  CHECKOUT_SESSION_CREATED: 'CHECKOUT_SESSION_CREATED',
  COMPLETED: 'COMPLETED',
  INACTIVE: 'INACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYOUT_INITIATED: 'PAYOUT_INITIATED',
  PENDING: 'PENDING',
  PENDING_ACTIVATION: 'PENDING_ACTIVATION',
  PLAN_CREATED: 'PLAN_CREATED',
  PRODUCT_CREATED: 'PRODUCT_CREATED',
  SUSPENDED: 'SUSPENDED',
  VERIFIED: 'VERIFIED',
};

export const concatCleaningTypes = c => {
  return c
    .map(v => {
      return `${v.qty} ${v.cleanType}`;
    })
    .join(', ');
};

export const formatTime = date => {
  return moment(date).format('H:mm');
};

export const orderHistoryMapping = {
  ACCEPTED: 'SCHEDULED',
  ASSIGNED: 'SCHEDULED',
  CANCELED: 'CANCELED',
  COMPLETED: 'CLOSED',
  IN_PROGRESS: 'ONGOING',
  PENDING: 'UPCOMING',
};

export const orderHistoryMappingColorCode = {
  ACCEPTED: 'primary',
  ASSIGNED: 'primary',
  COMPLETED: 'green',
  CANCELED: 'lightred',
  IN_PROGRESS: 'lightblue',
  PENDING: 'darkorange',
};

export const frequencyLabels = {
  one_off: 'One-off',
  weekly: 'Weekly',
  forthnightly: 'Forthnightly',
  monthly: 'Monthly',
};

export const createStripeConnectURL = (firebase, email, CSRFToken) => {
  const stripeConnectURL = 'https://connect.stripe.com/express/oauth/authorize';
  const redirectURI = `${window.location.origin}/dashboard`;

  return firebase
    .firestore()
    .collection('settings')
    .doc('stripe_connect_client_id')
    .get()
    .then(doc => {
      const clientID = doc.data().value;

      return `${stripeConnectURL}?redirect_uri=${redirectURI}&client_id=${clientID}&state=${CSRFToken}&stripe_user[business_type]=individual&stripe_user[email]=${email}`;
    });
};

export const getHostExtension = () => {
  const { host } = window.location;

  return host.substring(host.indexOf('findmaids') + 10);
};

export const firstLetterUc = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const numbersOnly = n => {
  if (n) {
    const num = n.toString();
    const regex = /\D/gm;
    return num.replace(regex, '');
  }
  return '';
};
