import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { config } from 'firebase.config';
import { FirebaseAppProvider } from 'reactfire';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'firebase/performance';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={config} initPerformance>
    <App />
  </FirebaseAppProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
