import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  margin: 0 1.9rem;
`;

const RangeComponent = styled.input`
  & {
    -webkit-appearance: none;
    width: calc(100% + 3.8rem);
    margin-top: 1.55rem;
    margin-bottom: 1.55rem;
    margin-left: -1.9rem;
    margin-right: -1.9rem;
  }
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: rgba(225, 172, 30, 0.42);
    border-radius: 7.7px;
    border: 0px solid rgba(1, 1, 1, 0);
  }
  &::-webkit-slider-thumb {
    opacity: 0;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 3.8rem;
    width: 3.8rem;
    border-radius: 50%;
    background: #e1ac1e;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15.5px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: rgba(228, 180, 53, 0.42);
  }
  &::-moz-range-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: rgba(225, 172, 30, 0.42);
    border-radius: 7.7px;
    border: 0px solid rgba(1, 1, 1, 0);
  }
  &::-moz-range-thumb {
    opacity: 0;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 3.8rem;
    width: 3.8rem;
    border-radius: 50%;
    background: #e1ac1e;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: rgba(203, 155, 27, 0.42);
    border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 15.4px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  }
  &::-ms-fill-upper {
    background: rgba(225, 172, 30, 0.42);
    border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 15.4px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  }
  &::-ms-thumb {
    opacity: 0;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 3.8rem;
    width: 3.8rem;
    border-radius: 50%;
    background: #e1ac1e;
    cursor: pointer;
    height: 7px;
  }
  &:focus::-ms-fill-lower {
    background: rgba(225, 172, 30, 0.42);
  }
  &:focus::-ms-fill-upper {
    background: rgba(228, 180, 53, 0.42);
  }
`;

RangeComponent.defaultProps = {
  type: 'range',
};

const Badge = styled.div`
  background: #e1ac1e;
  border-radius: 50%;
  border: 0px solid rgba(0, 0, 0, 0);
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  height: 3.8rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 3.8rem;
  font-size: 1.6rem;
  transform: translateX(-50%);
  transition: all 0.1s;
`;

export const Range = ({ max, min, onChange, value, ...props }) => {
  const [left, setLeft] = React.useState(0);

  React.useEffect(() => {
    setLeft(((value - min) / (max - min)) * 100);

    return () => {};
  }, [value, max, min]);

  return (
    <Wrapper>
      <RangeComponent value={value} min={min} max={max} onChange={onChange} {...props} />
      <Badge style={{ left: `${left}%` }}>{value}</Badge>
    </Wrapper>
  );
};

Range.defaultProps = {
  max: 10,
  min: 1,
  onChange: () => {},
  value: 1,
};

Range.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};
