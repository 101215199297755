import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  adaptiveHeight: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,
  dots: false,
  infinite: true,
  slidesToScroll: 1,
  speed: 5000,
  variableWidth: true,
};

const Image = styled.img`
  height: 17rem;
  object-fit: cover;
  object-position: center;
  width: 25rem;
`;

const ImageBox = styled.div`
  display: block;
  height: 17rem;
  margin-right: 0.5rem;
  width: 25rem;
`;

const Wrapper = styled.section`
  margin-bottom: 1rem;
`;

export const ImageSlider = () => (
  <Wrapper>
    <Slider {...settings}>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
    </Slider>

    <Slider {...settings}>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
      <ImageBox>
        <Image src="https://picsum.photos/300" />
      </ImageBox>
    </Slider>
  </Wrapper>
);
