import styled from 'styled-components';
import { Label } from './Label';

export const FormGroup = styled.div`
  width: 100%;

  ${Label} {
    margin-bottom: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }
`;
