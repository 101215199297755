import styled, { css } from 'styled-components';

export const Checkbox = styled.div`
  align-items: center;
  border-radius: 0.5rem;
  border: 0.2rem solid ${props => props.theme.colors.dark};
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  margin-right: 1rem;
  position: relative;
  width: 2.5rem;

  &::after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    content: '';
    display: block;
    height: 14px;
    margin-bottom: 3px;
    opacity: 0;
    transform: rotate(45deg);
    width: 7px;
  }

  ${({ box }) =>
    box &&
    css`
      border-radius: 0.2rem;
      &::after {
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 0.2rem;
        border: none;
        content: '';
        display: block;
        height: 70%;
        left: 50%;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
      }
    `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
      overflow: hidden;
      &::after {
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 50%;
        border: none;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transform: unset;
        width: 100%;
      }
    `}

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${props => props.theme.colors.dark};
      &::after {
        opacity: 1;
      }
    `}
`;
